import {service} from '@mp/common/api';
import {FwId} from '../types';

export interface AirDatesData {
    id: number;
    seasonNumber: number;
    episodeNumber: number;
    duration: number;
    airDates: Array<AirDate>;
}

interface AirDate {
    airDateInt: number;
    country: string;
}

interface LoadFilmEpisodesAirDatesResult {
    id: number;
    data?: Array<AirDatesData>;
    error?: unknown;
}

export function loadFilmEpisodesAirDatesService(movieId: FwId, season: string | number, hasSeasons: boolean): Promise<Array<AirDatesData>> {
    return service
        .get<LoadFilmEpisodesAirDatesResult>('loadFilmEpisodesAirDates', {
            data: {id: movieId, season, hasSeasons},
            prefix: 'filmweb'
        })
        .then((response) => response.data?.data);
}
