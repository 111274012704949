import {isMobile} from '@mp/common/utils/isMobile';
import {Router} from '@mp/route';
import {HOST} from '../../../global';
import {MkId} from '../types';
import {resolveMovieTimeId} from './resolveMovieTimeId';

const MESSENGER_PREFIX: string = `fb-messenger://share/?link=${HOST}`;

export function getShareLink(props: {date: Date; mkId: MkId; startTime?: string}): string {
    const {mkId, date, startTime} = props;
    const movieTimeId: string = resolveMovieTimeId({mkId, startTime});
    const shareLink: string = Router.getUrlToCinemaProgramPage({date, id: movieTimeId ?? String(mkId)});

    if (isMobile()) {
        return `${MESSENGER_PREFIX}${shareLink}`;
    }
    return shareLink;
}
