import {service} from '@mp/common/api';
import {FwId} from '@mp/filmweb/types';
import {AxiosResponse} from 'axios';
import {Movie} from '../ui/inCinemaTab/types';

interface MovieDTO {
    id: string;
    fid: string;
    t_pl: string;
    t_en: string;
    year: string;
    poster: string;
    watched_date: string;
}

export function loadInCinemaMoviesService(): Promise<Array<Movie>> {
    return service.get<Array<MovieDTO>>('movies.loadInCinema').then((result: AxiosResponse<Array<MovieDTO>>) => resolveMovies(result.data));
}

function resolveMovies(rawMovies: Array<MovieDTO>): Array<Movie> {
    return rawMovies.map((raw) => ({
        id: Number(raw.id),
        fid: Number(raw.fid) as FwId,
        titlePl: raw.t_pl,
        titleEn: raw.t_en,
        year: Number(raw.year),
        poster: raw.poster,
        watchedDate: raw.watched_date
    }));
}
