import {Icon, SvgButton} from '@mp/common/svg';
import {toCurrency} from '@mp/common/utils/number';
import React, {JSX, useState} from 'react';
import {isAdmin} from '../../../global';
import {TravelPayment} from '../types';
import {Table} from './Table';

interface PaymentItemProps {
    payments: Array<TravelPayment>;
    onEditItem: (selectedItem: TravelPayment) => void;
    onAddItem: (name: string) => void;
}

export function PaymentItem(props: PaymentItemProps): JSX.Element {
    const [opened, setOpened] = useState<boolean>(false);

    const {payments} = props;
    const sumPayment: TravelPayment = resolveSquashedPaymentData(payments);

    return (
        <div className="mp-payment-item">
            <div className="mp-payment-item-header">
                <div>{sumPayment.name}</div>
                <div>{toCurrency(sumPayment.valueSum, {withPenny: false})}</div>
                <div>
                    <SvgButton icon={opened ? Icon.ChevronUp : Icon.ChevronDown} onClick={() => setOpened(!opened)} />
                </div>
            </div>
            {opened && (
                <div className="mp-payment-item-content">
                    <div className="table-container">
                        <Table
                            selectedTravel={payments}
                            onAddItemButtonClick={props.onAddItem}
                            onEditItemButtonClick={props.onEditItem}
                            isAdmin={isAdmin()}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

function resolveSquashedPaymentData(travelPayments: Array<TravelPayment>): TravelPayment {
    const name: string = travelPayments[0].name;
    return {
        id: name as undefined,
        name: name,
        date: null,
        notEditable: null,
        description: null,
        travelNameId: null,
        valueK: sumValues(({valueK}) => valueK),
        valueM: sumValues(({valueM}) => valueM),
        valueSum: sumValues(({valueSum}) => valueSum)
    };

    function sumValues(fn: (value: TravelPayment) => number): number {
        return travelPayments.map(fn).reduce((sum, current) => sum + current, 0);
    }
}
