import {TextInput} from '@mp/common/components/text-input/ui/TextInput';
import {Icon} from '@mp/common/svg';
import React, {JSX, useEffect, useRef, useState} from 'react';
import {TileItemParams, tileItems} from '../../../components/header/tileItems';
import {Loading} from '../../../components/loading';
import {getAppSettings} from '../../../global';
import styles from './HomePage.module.scss';
import {TileItem} from './TileItem';

export function HomePage(): JSX.Element {
    const allItems = useRef<Array<TileItemParams>>(null);
    const defaultItems = useRef<Array<TileItemParams>>(null);
    const [items, setItems] = useState<Array<TileItemParams>>();
    const searchInput = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const {homeItems} = getAppSettings();

        const _defaultItems: Array<TileItemParams> = homeItems.map(
            (id) => tileItems.getAllTitleItems().find(({id: tileId}) => tileId === id) ?? resolveUnknownTile(id)
        );
        defaultItems.current = _defaultItems;
        allItems.current = tileItems.getAllTitleItems();
        setItems(_defaultItems);
    }, []);

    if (items == null) {
        return <Loading />;
    }

    const handleSearchChange = () => {
        const value: string = searchInput.current.value;
        if (value) {
            setItems(allItems.current.filter(({labels}) => labels.includes(value)));
        } else {
            setItems(defaultItems.current);
        }
    };

    return (
        <div className={styles.page}>
            <div className={styles.search}>
                <TextInput clearButton={true} reference={searchInput} onChange={handleSearchChange} onClear={handleSearchChange} />
            </div>
            <div className={styles.grid}>{items.map(renderTileItem)}</div>
        </div>
    );
}

function renderTileItem(item: TileItemParams): JSX.Element {
    return <TileItem key={item.route} {...item} />;
}

function resolveUnknownTile(id: number): TileItemParams {
    return {id, route: '/', svgIcon: Icon.Question, title: 'Unknown', labels: ''};
}
