import {SvgButton, Icon} from '@mp/common/svg';
import {toDateString} from '@mp/common/utils/converters';
import classNames from 'classnames';
import React, {JSX} from 'react';
import {TravelPayment} from '../types';

const COL_ICON: string = 'col-icon';

interface TableProps {
    selectedTravel: Array<TravelPayment>;
    onAddItemButtonClick: (name: string) => void;
    onEditItemButtonClick: (item: TravelPayment) => void;
    isAdmin: boolean;
}

export function Table(props: TableProps): JSX.Element {
    const {selectedTravel, onAddItemButtonClick, onEditItemButtonClick, isAdmin} = props;
    const isDataSetInAnyRow: boolean = selectedTravel.some((f: TravelPayment) => f.date);
    return (
        <table>
            <tbody>
                <tr>
                    {isDataSetInAnyRow && <th>Data</th>}
                    <th>Opis</th>
                    <th>Klaudia</th>
                    <th>Maciej</th>
                    <th />
                </tr>
                {selectedTravel.map((travelItem: TravelPayment) => {
                    const {date} = travelItem;
                    return (
                        <tr key={travelItem.id}>
                            {isDataSetInAnyRow && <td className="col-date">{date ? toDateString(date, 'yyyy-mm-dd') : ''}</td>}
                            <td>{travelItem.description?.toUpperCase()}</td>
                            <td>{travelItem.valueK}</td>
                            <td>{travelItem.valueM}</td>
                            <td className={COL_ICON}>{renderEditButton(travelItem)}</td>
                        </tr>
                    );
                })}
                {renderSum()}
            </tbody>
        </table>
    );

    function renderEditButton(travelItem: TravelPayment): JSX.Element {
        const {notEditable} = travelItem;
        const optionalNotEditableClass: string = classNames({'not-editable': notEditable});

        if (travelItem.notEditable && !isAdmin) {
            return <SvgButton icon={Icon.Pencil} className={optionalNotEditableClass} />;
        }
        return <SvgButton icon={Icon.Pencil} className={optionalNotEditableClass} onClick={() => onEditItemButtonClick(travelItem)} />;
    }

    function renderSum(): JSX.Element {
        let sumM: number = 0;
        let sumK: number = 0;
        selectedTravel.forEach((d: TravelPayment) => {
            sumM += d.valueM;
            sumK += d.valueK;
        });
        const summary: string = `PODSUMOWANIE`;

        return (
            <tr className="sum">
                <td colSpan={isDataSetInAnyRow ? 2 : 1}>{summary}</td>
                <td>{sumK}</td>
                <td>{sumM}</td>
                <td className={COL_ICON} onClick={() => onAddItemButtonClick(selectedTravel[0]?.name ?? null)}>
                    <SvgButton icon={Icon.Plus} />
                </td>
            </tr>
        );
    }
}
