import {Icon, SvgButton} from '@mp/common/svg';
import {round} from '@mp/common/utils/number';
import classNames from 'classnames';
import React, {JSX} from 'react';
import {MovieInfo} from '../types';
import styles from './RatingBox.module.scss';

type RatingData = Required<Pick<MovieInfo, 'fwRate' | 'fwRateCount'>>;

interface RatingBoxParams {
    filmRating: RatingData;
    wantToWatch: boolean;
    onStarClick: () => void;
}

export function RatingBox(params: RatingBoxParams): JSX.Element {
    const {wantToWatch, filmRating, onStarClick} = params;
    const {fwRate, fwRateCount} = filmRating ?? {};

    if (fwRate == null) {
        return null;
    }

    return (
        <div className={classNames(styles.rating, {[styles.outdated]: !!onStarClick})}>
            <SvgButton icon={wantToWatch ? Icon.StarFilled : Icon.Star} onClick={onStarClick} />
            <span>
                {round(fwRate, 1).toFixed(1)} ({fwRateCount} ocen)
            </span>
        </div>
    );
}
