import './_costsEstimateListPage.scss';
import {useNumberQueryParams, useQueryParams} from '@mp/common/hooks/useQueryParams';
import {ImageCrop} from '@mp/common/image-crop/ui/ImageCrop';
import {Router} from '@mp/route';
import React, {JSX, useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {Loading} from '../../../components/loading';
import {updateCostsEstimateItemImageService} from '../services/costsEstimateItems.service';
import {loadCostsEstimateListService} from '../services/costsEstimateList.service';
import {CostEstimateListItem} from '../types';
import {CostsEstimatePage} from './CostsEstimatePage';

export function CostsEstimateListPage(): JSX.Element {
    const listId: number = useNumberQueryParams('listId');
    const editImageId: number = useNumberQueryParams('editImageId');
    const imgSrc: string = useQueryParams('imgSrc');

    const navigate = useNavigate();
    const [imageSource, setImageSource] = useState<string>();
    const [costsEstimates, setCostsEstimates] = useState<Array<CostEstimateListItem>>(null);

    useEffect(() => {
        loadCostsEstimateListService().then((data) => setCostsEstimates(data));
    }, []);

    if (costsEstimates == null) {
        return <Loading />;
    }

    if (editImageId !== null) {
        const updateIsImage = () => {
            const img = new Image();
            img.onload = () => {
                setImageSource(imgSrc);
            };
            img.onerror = () => {
                setImageSource(null);
            };
            img.src = imgSrc;
        };

        updateIsImage();

        if (imageSource === undefined) {
            return <Loading />;
        }

        return (
            <div>
                <ImageCrop
                    buttonName="Ustaw obraz"
                    imgSrc={imgSrc}
                    aspect={null}
                    onGetCroppedImage={(img) => {
                        updateCostsEstimateItemImageService({id: editImageId, base64Image: img.base64}).then(({success}) => {
                            if (success) {
                                navigate(Router.getUrlToCostsEstimate({listId: listId}));
                            }
                        });
                    }}
                />
            </div>
        );
    }

    if (listId !== null) {
        const costEstimate: CostEstimateListItem = costsEstimates.find(({id}) => id === listId);

        if (costEstimate) {
            return <CostsEstimatePage listId={listId} section={costEstimate.name} />;
        }
    }

    return (
        <div className="mp-costs-estimate-list-page">
            {costsEstimates.map((item) => (
                <Link key={item.id} to={Router.getUrlToCostsEstimate({listId: item.id})}>
                    {item.name}
                </Link>
            ))}
        </div>
    );
}
