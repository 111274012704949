import {Gift, GroupedGifts} from '../types';

export function mapGiftsToGroupedGifts(gifts: Gift[]): GroupedGifts[] {
    if (gifts == null) {
        return null;
    }
    const KEY_TO_GROUPED_GIFTS: Map<string, GroupedGifts> = new Map();

    gifts.forEach((entry) => {
        const key: string = `${entry.personId}-${entry.date}`;
        const group: GroupedGifts = KEY_TO_GROUPED_GIFTS.get(key);
        if (group) {
            group.gifts.push(entry);
        } else {
            KEY_TO_GROUPED_GIFTS.set(key, {date: entry.date, personId: entry.personId, gifts: [entry]});
        }
    });

    return Array.from(KEY_TO_GROUPED_GIFTS.values());
}
