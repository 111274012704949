import classNames from 'classnames';
import React, {JSX} from 'react';
import {Link} from 'react-router-dom';

const MP_TABS_NAVIGATION: string = 'mp-tabs-navigation';

export interface TabItem {
    name: string;
    selected: boolean;
    url: string;
    onClick?: () => void;
}

export interface TabAction {
    id?: string;
    name: JSX.Element | string;
    onClick: () => void;
}

export interface TabsNavigationProps {
    tabs: Array<TabItem>;
    actions?: Array<TabAction>;
    onTabClicked?: () => void;
}

export class TabsNavigation extends React.Component<TabsNavigationProps> {
    constructor(props: TabsNavigationProps) {
        super(props);

        this.state = {};
        this.renderItem = this.renderItem.bind(this);
    }

    public render() {
        const {tabs, actions} = this.props;

        return (
            <div className={MP_TABS_NAVIGATION}>
                <div className={`${MP_TABS_NAVIGATION}-left`}>{tabs.map(this.renderItem)}</div>
                <div className={`${MP_TABS_NAVIGATION}-right`}>{actions?.map(this.renderAction)}</div>
            </div>
        );
    }

    private renderItem(tab: TabItem): JSX.Element {
        const {name, selected, onClick, url} = tab;
        const itemClass: string = classNames(`${MP_TABS_NAVIGATION}-left-item`, {selected});

        if (url == null) {
            return (
                <span key={name} className={itemClass} onClick={onClick}>
                    {tab.name}
                </span>
            );
        }

        return (
            <Link
                key={name}
                to={url}
                className={itemClass}
                onClick={() => {
                    if (onClick) {
                        onClick();
                    }
                    if (this.props.onTabClicked) {
                        this.props.onTabClicked();
                    }
                }}
            >
                {tab.name}
            </Link>
        );
    }

    private renderAction(action: TabAction): JSX.Element {
        return (
            <div key={action.id} onClick={action.onClick}>
                {action.name}
            </div>
        );
    }
}
