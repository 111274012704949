import React, {JSX} from 'react';
import {ReactComponent as AppleWholeSolid} from './apple-whole-solid.svg';
import {ReactComponent as AtomSolid} from './atom-solid.svg';
import {ReactComponent as BanSolid} from './ban-solid.svg';
import {ReactComponent as BreadSliceSolid} from './bread-slice-solid.svg';
import {ReactComponent as CandyCaneSolid} from './candy-cane-solid.svg';
import {ReactComponent as CatSolid} from './cat-solid.svg';
import {ReactComponent as CheeseSolid} from './cheese-solid.svg';
import {ReactComponent as DropletSolid} from './droplet-solid.svg';
import {ReactComponent as DrumstickBiteSolid} from './drumstick-bite-solid.svg';
import {ReactComponent as FishFinsSolid} from './fish-fins-solid.svg';
import {ReactComponent as HouseChimneySolid} from './house-chimney-solid.svg';
import {ReactComponent as MartiniGlassSolid} from './martini-glass-solid.svg';
import {ReactComponent as PencilSolid} from './pencil-solid.svg';
import {ReactComponent as PepperHotSolid} from './pepper-hot-solid.svg';
import {ReactComponent as PillsSolid} from './pills-solid.svg';
import {ReactComponent as QuestionSolid} from './question-solid.svg';
import {ReactComponent as SeedlingSolid} from './seedling-solid.svg';
import {ReactComponent as ShirtSolid} from './shirt-solid.svg';
import {ReactComponent as SnowflakeRegular} from './snowflake-regular.svg';
import {ReactComponent as TagSolid} from './tag-solid.svg';
import {ReactComponent as ToiletPaperSolid} from './toilet-paper-solid.svg';
import {ReactComponent as TvSolid} from './tv-solid.svg';

export enum Icon {
    AppleWhole = 'apple-whole-solid',
    Atom = 'atom-solid',
    Ban = 'ban-solid',
    BreadSlice = 'bread-slice-solid',
    CandyCane = 'candy-cane-solid',
    Cat = 'cat-solid',
    Cheese = 'cheese-solid',
    Droplet = 'droplet-solid',
    DrumstickBite = 'drumstick-bite-solid',
    FishFins = 'fish-fins-solid',
    HouseChimney = 'house-chimney-solid',
    MartiniGlass = 'martini-glass-solid',
    Pencil = 'pencil-solid',
    PepperHot = 'pepper-hot-solid',
    Pills = 'pills-solid',
    Question = 'question-solid',
    Seedling = 'seedling-solid',
    Shirt = 'shirt-solid',
    Snowflake = 'snowflake-regular',
    TV = 'tv-solid',
    Tag = 'tag-solid',
    ToiletPaper = 'toilet-paper-solid'
}

const MAP: Map<Icon, JSX.Element> = new Map<Icon, JSX.Element>([
    [Icon.AppleWhole, <AppleWholeSolid key={Icon.AppleWhole} />],
    [Icon.Atom, <AtomSolid key={Icon.Atom} />],
    [Icon.Ban, <BanSolid key={Icon.Ban} />],
    [Icon.BreadSlice, <BreadSliceSolid key={Icon.BreadSlice} />],
    [Icon.CandyCane, <CandyCaneSolid key={Icon.CandyCane} />],
    [Icon.Cat, <CatSolid key={Icon.Cat} />],
    [Icon.Cheese, <CheeseSolid key={Icon.Cheese} />],
    [Icon.Droplet, <DropletSolid key={Icon.Droplet} />],
    [Icon.DrumstickBite, <DrumstickBiteSolid key={Icon.DrumstickBite} />],
    [Icon.FishFins, <FishFinsSolid key={Icon.FishFins} />],
    [Icon.HouseChimney, <HouseChimneySolid key={Icon.HouseChimney} />],
    [Icon.MartiniGlass, <MartiniGlassSolid key={Icon.MartiniGlass} />],
    [Icon.Pencil, <PencilSolid key={Icon.Pencil} />],
    [Icon.PepperHot, <PepperHotSolid key={Icon.PepperHot} />],
    [Icon.Pills, <PillsSolid key={Icon.Pills} />],
    [Icon.Question, <QuestionSolid key={Icon.Question} />],
    [Icon.Seedling, <SeedlingSolid key={Icon.Seedling} />],
    [Icon.Shirt, <ShirtSolid key={Icon.Shirt} />],
    [Icon.Snowflake, <SnowflakeRegular key={Icon.Snowflake} />],
    [Icon.TV, <TvSolid key={Icon.TV} />],
    [Icon.Tag, <TagSolid key={Icon.Tag} />],
    [Icon.ToiletPaper, <ToiletPaperSolid key={Icon.ToiletPaper} />]
]);

export function Svg(props: {name: Icon}): JSX.Element {
    return MAP.get(props.name) ?? null;
}
