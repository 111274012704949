import {BigButton} from '@mp/common/components/button/ui/Button';
import {Router} from '@mp/route';
import React, {JSX, useState} from 'react';
import {Link} from 'react-router-dom';
import {Restaurant} from '../types';
import {RatingStars} from './RatingStars';

const ALL: string = 'wszystkie';

interface Props {
    restaurantFilterId: number;
    restaurants: Array<Restaurant>;
}

export function RestaurantFilter(props: Props): JSX.Element {
    const {restaurants, restaurantFilterId} = props;
    const [isOpened, setIsOpened] = useState(false);

    return (
        <div className="sushi-page-toolbar-filter">
            <BigButton title={`Restauracja: ${resolveRestaurantName()}`} noMargin onClick={() => setIsOpened(!isOpened)} />
            {isOpened && renderRestaurantList()}
        </div>
    );

    function resolveRestaurantName(): string {
        if (restaurantFilterId && restaurants.some((f) => f.id === restaurantFilterId)) {
            return restaurants.find((f) => f.id === restaurantFilterId).name;
        }
        return ALL;
    }

    function renderRestaurantList(): JSX.Element {
        return renderFilters();
    }

    function renderFilters(): JSX.Element {
        if (isOpened) {
            return (
                <ul>
                    {renderFilter(null)}
                    {restaurants.map((filter) => renderFilter(filter))}
                </ul>
            );
        }
    }

    function renderFilter(restaurant: Restaurant): JSX.Element {
        if (restaurant) {
            const {id, name, rate} = restaurant;
            return (
                <Link key={id} to={Router.getUrlToSushiPage({filter: id})} onClick={() => setIsOpened(false)}>
                    <li>
                        {name} <RatingStars rate={rate} maxRate={5} />
                    </li>
                </Link>
            );
        }
        return (
            <Link key={0} to={Router.getUrlToSushiPage({filter: null})} onClick={() => setIsOpened(false)}>
                <li>{ALL}</li>
            </Link>
        );
    }
}
