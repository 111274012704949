import {Icon, SvgIcon} from '@mp/common/svg';
import {toDate} from '@mp/common/utils/converters';
import {currentDate} from '@mp/common/utils/date';
import classNames from 'classnames';
import React, {JSX} from 'react';
import {ChecklistItem} from '../types';

const MP_CHECK_ROW: string = 'mp-check-row';

interface CheckRowProps {
    item: ChecklistItem;
    onClick: () => void;
    onContextMenu: (event: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>) => void;
}

export function CheckRow(props: CheckRowProps): JSX.Element {
    const {checked, name, deadline, maintainerUserId} = props.item;
    const className: string = checked ? `${MP_CHECK_ROW}-checked` : MP_CHECK_ROW;
    let date: Date = null;

    const content: string = deadline ? `(${deadline}) ${name}` : name;
    const isAfterDeadline: boolean = deadline && toDate(deadline) < currentDate();

    return (
        <div
            className={classNames(className, `maintainer-id-${maintainerUserId}`)}
            onClick={props.onClick}
            onContextMenu={(event) => {
                event.preventDefault();
                props.onContextMenu(event);
            }}
            onTouchStart={() => {
                date = new Date();
            }}
            onTouchEnd={(event) => {
                const isLongClick: boolean = new Date().getTime() - date?.getTime() > 500;
                if (isLongClick) {
                    props.onContextMenu(event);
                }
            }}
        >
            <div>
                <SvgIcon icon={checked ? Icon.CircleCheck : Icon.Circle} />
            </div>
            <div>
                <span className={classNames({'after-deadline': isAfterDeadline})}>{content}</span>
            </div>
        </div>
    );
}
