import './_simpleToolBar.scss';
import React, {JSX} from 'react';
import {Link} from 'react-router-dom';
import {Color} from '../../../colors';
import {Icon, SvgButton} from '../../../svg';

interface SimpleToolBarProps {
    name: string | JSX.Element;
    backgroundColor?: Color;
    backLink?: string;
    onAddClick?: () => void;
}

export function SimpleToolBar(props: SimpleToolBarProps): JSX.Element {
    const {name, backgroundColor = Color.GrayLight, onAddClick, backLink} = props;
    return (
        <div className="mp-simple-toolbar" style={{backgroundColor}}>
            {!!backLink && (
                <Link to={backLink}>
                    <SvgButton icon={Icon.ArrowLeft} />
                </Link>
            )}
            <div className="mp-simple-toolbar-name">
                <span style={{paddingLeft: backLink ? '0' : '8px'}}>{name}</span>
            </div>
            <div className="mp-simple-toolbar-actions">{!!onAddClick && <SvgButton icon={Icon.Plus} onClick={onAddClick} />}</div>
        </div>
    );
}
