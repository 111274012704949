import './_movieDetailsWindow.scss';
import {Button} from '@mp/common/components/button/ui/Button';
import {TextInput} from '@mp/common/components/text-input/ui/TextInput';
import {Modal} from '@mp/common/modals/Modal';
import {ServerResponse} from '@mp/common/types';
import {FwId, InfoType, SearchMovieResult} from '@mp/filmweb/types';
import {changeImageSize} from '@mp/filmweb/utils/filmImage';
import {resolveLocaleTitle, resolveOriginalTitle} from '@mp/filmweb/utils/filmTitle';
import {getFullImagePath} from '@mp/filmweb/utils/imagePath';
import {createLinkToMovie} from '@mp/filmweb/utils/link';
import React, {JSX} from 'react';
import {insertSeriesService} from '../../../episodes/services';
import {addMovieService} from '../../services';

const MP_MOVIE_DETAILS_WINDOW: string = 'mp-movie-details-window';

const WINDOW_CONTENT: string = `${MP_MOVIE_DETAILS_WINDOW}-content`;
const WINDOW_INPUT_CONTAINER: string = `${WINDOW_CONTENT}-input-container`;
const WINDOW_INPUT_ROW_DOUBLE: string = `${WINDOW_INPUT_CONTAINER}-row-double`;

interface MovieDetailsWindowState {
    serverResponse: ServerResponse;
}

interface MovieDetailsWindowProps {
    movieDetailsWindowData: SearchMovieResult;
    onExitCallback: () => void;
    onMovieUpdateSuccess: (id: FwId, watched: boolean) => void;
}

export class MovieDetailsWindow extends React.Component<MovieDetailsWindowProps, MovieDetailsWindowState> {
    private readonly titleInputPl: React.RefObject<HTMLInputElement>;
    private readonly titleInputEn: React.RefObject<HTMLInputElement>;
    private readonly cinemaCheckbox: React.RefObject<HTMLInputElement>;

    constructor(props: MovieDetailsWindowProps) {
        super(props);

        this.titleInputPl = React.createRef<HTMLInputElement>();
        this.titleInputEn = React.createRef<HTMLInputElement>();
        this.cinemaCheckbox = React.createRef<HTMLInputElement>();

        this.insertMovie = this.insertMovie.bind(this);
        this.switchTitles = this.switchTitles.bind(this);

        this.state = {
            serverResponse: null
        };
    }

    public render() {
        const movie: SearchMovieResult = this.props.movieDetailsWindowData;
        return (
            <Modal title={movie.info.title} handleClose={() => this.props.onExitCallback()} footer={this.renderFooter()}>
                <div className={MP_MOVIE_DETAILS_WINDOW}>{this.renderWindowContent()}</div>
            </Modal>
        );
    }

    private renderWindowContent() {
        const movie: SearchMovieResult = this.props.movieDetailsWindowData;
        return (
            <div className={WINDOW_CONTENT}>
                <img
                    onClick={() => window.open(createLinkToMovie(movie.fid), '_blank')}
                    alt="Plakat"
                    src={getFullImagePath(changeImageSize(movie.info.posterPath, 3))}
                />
                <div className={WINDOW_INPUT_CONTAINER}>
                    <div className={WINDOW_INPUT_ROW_DOUBLE}>
                        <span>Kino:</span>
                        <input type="checkbox" defaultChecked={false} ref={this.cinemaCheckbox} />
                        <span>Rok:</span>
                        <TextInput disabled={true} defaultValue={movie.info.year + ''} />
                    </div>
                    <TextInput title="Tytuł (PL)" defaultValue={resolveLocaleTitle(movie)} reference={this.titleInputPl} />
                    <TextInput title="Tytuł (Oryginalny)" defaultValue={resolveOriginalTitle(movie)} reference={this.titleInputEn} />
                </div>
                {this.renderServiceResponse()}
            </div>
        );
    }

    private renderFooter(): JSX.Element {
        return (
            <div>
                <Button title="Obejrzane" className="watched" onClick={() => this.insertMovie(true)} />
                <Button title="Do obejrzenia" className="to-watch" onClick={() => this.insertMovie(false)} />
                <Button title="Zamień tytuły" onClick={this.switchTitles} />
            </div>
        );
    }

    private renderServiceResponse(): JSX.Element {
        const serviceResponse: ServerResponse = this.state.serverResponse;
        if (serviceResponse) {
            return <div className={serviceResponse.success ? 'response-success' : 'response-error'}>{serviceResponse.description}</div>;
        }
    }

    private switchTitles(): void {
        const temp: string = this.titleInputPl.current.value;
        this.titleInputPl.current.value = this.titleInputEn.current.value;
        this.titleInputEn.current.value = temp;
    }

    private insertMovie(watched: boolean): void {
        const movie: SearchMovieResult = this.props.movieDetailsWindowData;

        if (movie.type === InfoType.Serial) {
            const {fid, info} = movie;
            insertSeriesService({
                id: null,
                fid,
                hasSeasons: true,
                day: 1,
                season: 1,
                episode: 0,
                end: false,
                visible: true,
                name: info.originalTitle ?? info.title,
                link: null,
                nextAirDate: null
            }).then(({success}) => success && window.location.reload());
            return;
        }

        const cinema: boolean = this.cinemaCheckbox.current.checked;
        addMovieService({
            film: movie,
            cinema,
            watched,
            titlePl: this.titleInputPl.current.value,
            titleOrg: this.titleInputEn.current.value
        }).then((serverResponse) => {
            this.setState({serverResponse}, () => {
                if (serverResponse.success) {
                    setTimeout(() => this.props.onMovieUpdateSuccess(movie.fid, watched), 600);
                }
            });
        });
    }
}
