import {MkId} from '../types';

const SEPARATOR = '__';

export function resolveMovieTimeId(params: {mkId: MkId; startTime: string}): string {
    const {mkId, startTime} = params;
    if (!mkId || !startTime) {
        return null;
    }
    return `${mkId}${SEPARATOR}${startTime.replace(':', '')}`;
}

export function resolveMovieId(movieTimeId: string): string {
    return movieTimeId.split(SEPARATOR)[0];
}
