export function isEmpty(array: Array<unknown>): boolean {
    if (!Array.isArray(array)) {
        return true;
    }
    return array.length === 0;
}

export function isNotEmpty(array: Array<unknown>): boolean {
    return !isEmpty(array);
}

export function firstElement<T>(array: Array<T>) {
    if (isNotEmpty(array)) {
        return array[0];
    }
    return null;
}

export function lastElement<T>(array: Array<T>) {
    if (isNotEmpty(array)) {
        return array[array.length - 1];
    }
    return null;
}

export function secondToLastElement<T>(array: Array<T>) {
    if (isNotEmpty(array) && array.length > 1) {
        return array[array.length - 2];
    }
    return null;
}

export function getIntersection(array1: string[], array2: string[]): string[] {
    return array1.filter((element) => array2.includes(element));
}

export function getElements<T>(array: Array<T>, count: number): Array<T> {
    if (isEmpty(array)) {
        return array;
    }
    const newArray: Array<T> = [];
    for (let i: number = 0; i < count; i++) {
        if (i >= array.length) {
            break;
        }
        newArray.push(array[i]);
    }
    return newArray;
}

export function getUniqueStrings(array: Array<string>): Array<string> {
    if (isEmpty(array)) {
        return [];
    }
    const set: Set<string> = new Set();
    array.forEach((x) => set.add(x));
    return Array.from(set);
}

export function removeEmptyEntries(array: Array<string>): Array<string> {
    if (isEmpty(array)) {
        return [];
    }
    return array.filter((f) => !!f);
}

export function shuffle<T>(array: Array<T>): Array<T> {
    if (isEmpty(array)) {
        return array;
    }
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}
