import React, {ChangeEvent, CSSProperties, JSX, Ref} from 'react';

interface CheckBoxParams {
    defaultChecked?: boolean;
    title?: string;
    name?: string;
    disabled?: boolean;
    style?: CSSProperties;
    reference?: Ref<HTMLInputElement>;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export function CheckBox(params: CheckBoxParams): JSX.Element {
    const {title} = params;
    return (
        <div className="mp-check-box">
            {title && <div className="mp-check-box-title">{title}</div>}
            <input
                type="checkbox"
                ref={params.reference}
                defaultChecked={params.defaultChecked}
                disabled={params.disabled}
                style={params.style}
                name={params.name}
                onChange={params.onChange}
            />
        </div>
    );
}
