import './_cinemaProgramPage.scss';
import {Button} from '@mp/common/components/button/ui/Button';
import {TextArea} from '@mp/common/components/text-area/ui/TextArea';
import {useQueryParams} from '@mp/common/hooks/useQueryParams';
import {isEmpty} from '@mp/common/utils/array';
import {toDateString} from '@mp/common/utils/converters';
import {addDay} from '@mp/common/utils/date';
import {DATE_PARAM, Router} from '@mp/route';
import React, {JSX, useEffect, useRef, useState} from 'react';
import {Navigate, useParams} from 'react-router-dom';
import {isAdmin} from '../../../global';
import {loadCinemaProgramService} from '../services';
import {addCacheCinemaProgramService} from '../services/cinameProgram.service';
import {MovieInfo} from '../types';
import {resolveMovieId} from '../utils/resolveMovieTimeId';
import styles from './CinemaProgramPage.module.scss';
import {DateNavigation, NavigationDates} from './DateNavigation';
import {MovieInfoBox} from './MovieInfoBox';
import {MovieInfoBoxSkeleton} from './MovieInfoBoxSkeleton';
import {MovieInfoSingleSchedule, ScheduleModal} from './ScheduleModal';

interface CinemaProgramProps {
    date: Date;
    id: string;
}

export function CinemaProgramPage(): JSX.Element {
    const propsDate: string = useQueryParams(DATE_PARAM);
    const {movieId} = useParams<{movieId: string}>();

    if (!propsDate) {
        return <Navigate to={Router.getUrlToCinemaProgramPage({date: new Date()})} />;
    }
    return <CinemaProgramPageFC date={new Date(propsDate)} id={movieId} />;
}

function CinemaProgramPageFC({date, id}: CinemaProgramProps): JSX.Element {
    const [navigationDates, setNavigationDates] = useState<NavigationDates>();
    const [errorStatus, setErrorStatus] = useState<number>();
    const [isCacheUsed, setIsCacheUsed] = useState<boolean>();
    const [cachedDates, setCachedDates] = useState<Date[]>([]);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [moviesInfo, setMoviesInfo] = useState<Array<MovieInfo>>([]);
    const [selectedMovieInfo, setSelectedMovieInfo] = useState<MovieInfoSingleSchedule>(null);

    useEffect(() => {
        const resolveNavigationDates = (): NavigationDates => {
            let prevDate: Date = addDay(date, -1);
            let nextDate: Date = addDay(date, 1);
            const currentDate: Date = new Date();
            const maxDate: Date = addDay(currentDate, 6);

            if (prevDate.getTime() < currentDate.getTime()) {
                if (isEmpty(cachedDates)) {
                    return {prevDate: null, nextDate: null};
                }
                prevDate = cachedDates.find((entry) => entry.getTime() <= prevDate.getTime()) ?? null;
            }
            if (nextDate.getTime() < currentDate.getTime()) {
                if (isEmpty(cachedDates)) {
                    return {prevDate: null, nextDate: null};
                }
                nextDate = cachedDates.findLast((entry) => entry.getTime() >= nextDate.getTime()) ?? null;
            }
            if (nextDate.getTime() >= maxDate.getTime()) {
                nextDate = null;
                if (prevDate.getTime() >= maxDate.getTime()) {
                    prevDate = null;
                }
            }

            return {prevDate, nextDate};
        };

        setNavigationDates(resolveNavigationDates());
    }, [cachedDates, date]);

    useEffect(() => {
        setIsLoaded(false);

        loadCinemaProgramService(date).then((result) => {
            if (isEmpty(cachedDates)) {
                setCachedDates(result.cachedDates);
            }
            setMoviesInfo(result.moviesInfo);
            setErrorStatus(result.errorStatus);
            setIsCacheUsed(result.isCached);
            setIsLoaded(true);
        });
    }, [date]);

    return (
        <div className="mp-cinema-program">
            <DateNavigation currentDate={date} navigationDates={navigationDates} />
            {renderProgram()}
        </div>
    );

    function selectMovieBasedOnId(): void {
        if (id) {
            requestAnimationFrame(() => {
                const color: string = '#373737';
                const element: HTMLElement = document.getElementById(id);
                if (element) {
                    element.style.backgroundColor = color;
                    element.scrollIntoView();
                } else {
                    const timeScheduleElement: HTMLElement = document.querySelector(`[data-custom-stime="${id}"]`);
                    if (timeScheduleElement) {
                        timeScheduleElement.setAttribute('selected', String(true));
                        const movieEl: HTMLElement = document.getElementById(resolveMovieId(id));
                        if (movieEl) {
                            movieEl.scrollIntoView();
                            movieEl.style.backgroundColor = color;
                        }
                    }
                }
            });
        }
    }

    function renderProgram(): JSX.Element {
        if (!isLoaded) {
            return (
                <>
                    {[{}, {}, {}].map((_, index) => (
                        <MovieInfoBoxSkeleton key={index} />
                    ))}
                </>
            );
        }

        if (errorStatus) {
            return (
                <>
                    {errorStatus === 400 && <div className="empty-program">Nie można pobrać repertuaru dla wybranej daty</div>}
                    {errorStatus === 401 && <div className="empty-program">Request został zablokowany</div>}
                    {isAdmin() && errorStatus === 401 && <FailedToLoadAdmin date={date} />}
                </>
            );
        }

        if (isEmpty(moviesInfo)) {
            return <div className="empty-program">Brak seansów na wybrany dzień</div>;
        }

        selectMovieBasedOnId();

        return (
            <>
                {moviesInfo.map((movieInfo) => (
                    <MovieInfoBox
                        isCacheUsed={isCacheUsed}
                        key={movieInfo.title}
                        movieInfo={movieInfo}
                        date={date}
                        onScheduleClick={(schedule) => setSelectedMovieInfo({movieInfo, schedule})}
                    />
                ))}
                {selectedMovieInfo && (
                    <ScheduleModal date={date} selectedMovieInfo={selectedMovieInfo} handleClose={() => setSelectedMovieInfo(null)} />
                )}
            </>
        );
    }
}

function FailedToLoadAdmin({date}: {date: Date}): JSX.Element {
    const textAreaProgram = useRef<HTMLTextAreaElement>(null);
    const dateString: string = toDateString(date);
    const link: string = `https://multikino.pl/api/microservice/showings/cinemas/0004/films?showingDate=${dateString}T00:00:00&minEmbargoLevel=3&includesSession=true&includeSessionAttributes=true`;

    return (
        <div className={styles.failedLoadProgram}>
            <a href={link} target="_blank" rel="noreferrer">
                Repertuar
            </a>
            <iframe src={link} />
            <TextArea reference={textAreaProgram} />
            <Button
                title="Wyślij"
                onClick={() => {
                    const value: string = textAreaProgram.current.value;
                    if (value) {
                        addCacheCinemaProgramService({date: dateString, program: value}).then(({success}) => {
                            if (success) {
                                window.location.reload();
                            }
                        });
                    }
                }}
            />
        </div>
    );
}
