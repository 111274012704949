import {service} from '@mp/common/api';
import {isNotEmpty} from '@mp/common/utils/array';
import {VodProvider} from '../types';

let CACHE: VodProvider[] = null;

export async function loadAllVodProvidersService(): Promise<VodProvider[]> {
    if (CACHE) {
        return CACHE;
    }

    return service
        .get<VodProvider[]>('loadAllVodProviders', {prefix: 'filmweb'})
        .then((result): VodProvider[] => {
            return result.data;
        })
        .then((data) => {
            if (isNotEmpty(data)) {
                CACHE = data;
            }
            return data;
        });
}
