import './_calendate.scss';
import React, {JSX, MouseEvent} from 'react';
import {copyDate, getDayShortNames, getMonthName} from '../../../utils/date';
import {getVisibleDatesInGrid} from '../utils/gridUtils';

const DATA_CELL: string = 'data-cell';

interface CalendarProps<T> {
    currentDate: Date;
    displayDates: Array<T>;
    onDateClick: (cellDate: Date, dates: Array<T>) => void;
    renderCellFn: (cellDate: Date, dates: Array<T>) => JSX.Element;
}

export function Calendar<T extends {date: Date}>(props: CalendarProps<T>): JSX.Element {
    const {currentDate, onDateClick, renderCellFn, displayDates = []} = props;

    const renderWeekDays = (): Array<JSX.Element> => {
        return getDayShortNames().map((day, i) => <div key={i}>{day}</div>);
    };

    const handleGridClick = (event: MouseEvent<HTMLElement>): void => {
        const target: HTMLElement = event.target as HTMLElement;
        const element: HTMLElement = target.getAttribute(DATA_CELL) ? target : target.closest(`[${DATA_CELL}]`);
        if (element) {
            const day: number = Number(element.getAttribute(DATA_CELL));
            const dates: Array<T> = displayDates.filter((date) => date.date.getDate() === day);
            onDateClick(copyDate(currentDate, {setDay: day}), dates);
        }
    };

    return (
        <div className="mp-calendar">
            <div className="mp-calendar-header">{getMonthName(currentDate)}</div>
            <div className="mp-calendar-days">{renderWeekDays()}</div>
            <div className="mp-calendar-grid" onClick={handleGridClick}>
                {getVisibleDatesInGrid(currentDate).map((gridDate, i) => {
                    const {date: gDate, isCurrentMonth} = gridDate;
                    const dates: Array<T> = displayDates.filter((entry) => entry.date.getDate() === gridDate.date.getDate());

                    return (
                        <div key={i} data-cell={isCurrentMonth ? gridDate.date.getDate() : undefined}>
                            {renderCellFn(gDate, dates)}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
