import {Icon, SvgButton} from '@mp/common/svg';
import {Router} from '@mp/route';
import React, {JSX} from 'react';
import {Link} from 'react-router-dom';
import {resolveDayLabel} from '../utils/resolveDayLabel';
import styles from './DateNavigation.module.scss';

export interface NavigationDates {
    prevDate: Date;
    nextDate: Date;
}

export function DateNavigation({currentDate, navigationDates}: {currentDate: Date; navigationDates: NavigationDates}): JSX.Element {
    const label: string = resolveDayLabel(currentDate);
    const {prevDate, nextDate} = navigationDates ?? {};

    return (
        <div className={styles.nav}>
            <div className={styles.navl}>
                <NavButton date={prevDate} icon={Icon.ChevronLeft} />
            </div>
            <div className={styles.navc}>{label}</div>
            <div className={styles.navr}>
                <NavButton date={nextDate} icon={Icon.ChevronRight} />
            </div>
        </div>
    );
}

function NavButton({date, icon}: {date: Date; icon: Icon}): JSX.Element {
    if (date == null) {
        return null;
    }
    return (
        <Link to={Router.getUrlToCinemaProgramPage({date})}>
            <SvgButton icon={icon} />
        </Link>
    );
}
