import {service} from '@mp/common/api';
import {firstElement, isNotEmpty} from '@mp/common/utils/array';
import {toDateString} from '@mp/common/utils/converters';
import {getCurrentDateString} from '@mp/common/utils/date';
import {AxiosResponse} from 'axios';
import {MovieInfo, MkDbMovie, MultikinoApiFilm, MultikinoApiShowingGroup, Schedule} from '../types';
import {resolveDayLabel} from '../utils/resolveDayLabel';

const HTTPS_MULTIKINO: string = 'https://multikino.pl';

interface CinemaProgram {
    moviesInfo: MovieInfo[];
    cachedDates: Date[];
    errorStatus: number | null;
    isCached: boolean;
}

const CACHE: Map<number, CinemaProgram> = new Map();

export async function loadCinemaProgramService(date: Date): Promise<CinemaProgram> {
    const cachedData: CinemaProgram = CACHE.get(date.getTime());
    if (cachedData) {
        cachedData.isCached = true;
        return cachedData;
    }

    const dateString: string = toDateString(date, 'yyyy-mm-dd');
    const isToday: boolean = dateString === getCurrentDateString();
    const errors: string[] = [];

    return service
        .get('cinemaProgram', {data: {date: dateString}, isSilentError: true})
        .then((response: AxiosResponse): CinemaProgram => {
            const mkDbMovies: MkDbMovie[] = response.data.mk ?? [];

            const rawObjects: MultikinoApiFilm[] = response.data.program?.result;
            if (rawObjects == null) {
                return {
                    moviesInfo: null,
                    cachedDates: [],
                    errorStatus: response.status ?? -1,
                    isCached: false
                };
            }

            const movieInfos: MovieInfo[] = [];

            rawObjects.forEach((film: MultikinoApiFilm) => {
                const showingGroup: MultikinoApiShowingGroup = firstElement(film.showingGroups);

                const schedules: Schedule[] = showingGroup.sessions
                    ?.map(({startTime, endTime, bookingUrl, attributes, screenName}) => {
                        const expectedDateFragment: string = toDateString(date, 'yyyy-mm-dd');
                        if (!startTime.includes(expectedDateFragment)) {
                            errors.push(`Date error: expected "${expectedDateFragment}", got "${startTime}".`);
                        }
                        const startTimeDate: Date = new Date(startTime);

                        if (startTimeDate.getTime() < new Date().getTime()) {
                            return null;
                        }

                        const resolveFilmStartTime = (): string => {
                            if (endTime && film.runningTime) {
                                const startFilmTime: number = new Date(endTime).getTime() - film.runningTime * 60_000;
                                return toDateString(new Date(startFilmTime), 'HH:MM');
                            }
                            return null;
                        };

                        return {
                            startTime: toDateString(startTimeDate, 'HH:MM'),
                            endTime: toDateString(new Date(endTime), 'HH:MM'),
                            filmStartTime: resolveFilmStartTime(),
                            bookingLink: `${HTTPS_MULTIKINO}${bookingUrl}`,
                            screenName,
                            versionTitle: attributes
                                ?.map(({shortName}) => shortName)
                                .filter((value) => value.length < 10 && value.length > 1)
                                .join(', ')
                        };
                    })
                    .filter(Boolean);

                const filmParams: string[] = Array.from(film.genres) ?? [];
                if (film.runningTime) {
                    filmParams.push(String(film.runningTime) + ' min');
                }

                const {
                    fwId = null,
                    fwRate = null,
                    fwRateCount = null,
                    wantToWatchRate = null,
                    updated = null,
                    watched = null
                } = mkDbMovies.find(({mkId}) => mkId === film.filmId) ?? ({} as MkDbMovie);

                const resolveLabels = (): string[] => {
                    if (watched == null) {
                        return [];
                    }
                    return watched ? ['Obejrzane'] : ['Do obejrzenia'];
                };

                const movieInfo: MovieInfo = {
                    mkId: film.filmId,
                    title: film.filmTitle,
                    poster: film.posterImageSrc,
                    filmUrl: film.filmUrl,
                    filmParams: filmParams.join(' | '),
                    promoLabels: resolveLabels(),
                    dayTitle: resolveDayLabel(new Date(showingGroup.date)),
                    runningTime: film.runningTime,
                    synopsisShort: film.synopsisShort,
                    director: film.director,
                    cast:
                        film.cast
                            ?.split(',')
                            ?.filter(Boolean)
                            ?.map((entry) => entry.trim()) ?? [],
                    wantToWatchRate: wantToWatchRate ?? 0.1,
                    updated,
                    schedules,
                    fwId,
                    fwRate,
                    fwRateCount
                };

                movieInfos.push(movieInfo);
            });

            if (isNotEmpty(errors)) {
                alert(errors.join('\n'));
            }

            if (isToday) {
                const filteredMoviesInfo: MovieInfo[] = movieInfos.filter(({schedules}) => isNotEmpty(schedules));
                movieInfos.length = 0;
                movieInfos.push(...filteredMoviesInfo);
            }

            return {
                moviesInfo: movieInfos.sort((a, b) => b.wantToWatchRate - a.wantToWatchRate),
                cachedDates: (response.data.cachedDates ?? []).map((rawDate: string): Date => new Date(rawDate)),
                errorStatus: null,
                isCached: false
            };
        })
        .then((data) => {
            if (data.errorStatus == null || data.errorStatus === 400) {
                CACHE.set(date.getTime(), data);
            }
            return data;
        });
}
