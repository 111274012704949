import {Icon, SvgButton, SvgIcon} from '@mp/common/svg';
import {useIsInViewport} from '@mp/common/utils/isInViewPort';
import {loadCriticsRatingService} from '@mp/filmweb/services/loadCriticsRating.service';
import {loadFilmInfoService} from '@mp/filmweb/services/loadFilmInfo.service';
import {loadFilmRatingService} from '@mp/filmweb/services/loadFilmRating.service';
import {Film, FilmCriticsRating, FilmInfo, FilmRating} from '@mp/filmweb/types';
import {resolveLocaleTitle} from '@mp/filmweb/utils/filmTitle';
import {getFullImagePath} from '@mp/filmweb/utils/imagePath';
import {createLinkToMovie, createLinkToPerson} from '@mp/filmweb/utils/link';
import classNames from 'classnames';
import {getAppSettings} from 'global';
import React, {JSX, useRef, useState} from 'react';
import {VodProvidersModal} from '../common/VodProvidersModal';

interface FilmResultItemProps {
    readonly film: Film;
    readonly onDownloadFilmDetails: (updatedFilm: Film) => void;
    readonly onAddMovieClick: (film: Film) => void;
}

export function FilmResultItem(props: FilmResultItemProps): JSX.Element {
    const [isDataDownloaded, setIsDataDownloaded] = useState(false);
    const [vodModalVisible, setVodModalVisible] = useState<boolean>(false);

    const {film, onAddMovieClick, onDownloadFilmDetails} = props;
    const {fid, filmMainCast, info, rating} = film;
    let badge: string = film.type;
    if (film.info.watched) {
        badge += ' (obejrzany)';
    } else if (film.info.toWatch) {
        badge += ' (do obejrzenia)';
    }

    const ref = useRef(null);
    const isInViewport1 = useIsInViewport(ref);

    if (isInViewport1 && !isDataDownloaded && !info.id && !rating.id) {
        setIsDataDownloaded(true);
        const asyncServices: Array<Promise<unknown>> = [
            loadFilmInfoService(fid),
            loadFilmRatingService(fid),
            loadCriticsRatingService(fid)
        ];
        Promise.all(asyncServices).then((results: Array<unknown>) => {
            const filmInfo: FilmInfo = results[0] as FilmInfo;
            const filmUserRating: FilmRating = results[1] as FilmRating;
            const filmCriticsRating: FilmCriticsRating = results[2] as FilmCriticsRating;

            const updatedFilm: Film = film;
            updatedFilm.info = filmInfo;
            updatedFilm.rating = filmUserRating;
            updatedFilm.criticsRating = filmCriticsRating;
            onDownloadFilmDetails(updatedFilm);
        });
    }

    return (
        <div key={fid} ref={ref} className="fw-sr-element">
            <div className="fw-sr-element-left">
                <img src={getFullImagePath(info.posterPath)} alt="plakat" />
            </div>
            <div className="fw-sr-element-right">
                <div className="fw-sr-badge">{badge}</div>
                <div className="fw-sr-element-right-title">
                    <a href={createLinkToMovie(fid)}>{resolveLocaleTitle(film)}</a>
                </div>
                <div className="fw-sr-element-right-year">
                    {info.year}
                    <span className={classNames({'filmweb-id-hidden': !getAppSettings().moviesPageSearchResultShowId})}>{` (${fid})`}</span>
                </div>
                <div className="fw-sr-element-right-actors">
                    {filmMainCast.map((fmc) => (
                        <a key={fmc.id} href={createLinkToPerson(fmc.id)}>
                            {fmc.name}
                        </a>
                    ))}
                </div>
                {film.rating.id && (
                    <div className="fw-sr-element-right-rating">
                        <SvgIcon icon={Icon.StarFilled} />
                        <span>{rating.rate}</span>
                        <div className="community-rating">
                            <div>{rating.count?.toLocaleString()}</div>
                            <div>ocen</div>
                        </div>
                    </div>
                )}
                {!!film.criticsRating?.rateValue && (
                    <div className="fw-sr-element-right-critics-rating">
                        <span>{film.criticsRating.rateValue}</span>
                        <div className="critics-rating">
                            <div>{film.criticsRating.rateCount}</div>
                            <div>ocen</div>
                        </div>
                    </div>
                )}

                <div className="fw-sr-element-right-absolute-items">
                    <div className="fw-sr-element-right-reload-or-add">{renderReloadOrAdd()}</div>
                    <div className="fw-sr-element-right-vod" onClick={() => setVodModalVisible(true)}>
                        VOD
                    </div>
                </div>
            </div>
            {vodModalVisible && <VodProvidersModal fwId={film.fid} handleClose={() => setVodModalVisible(false)} title={film.info.title} />}
        </div>
    );

    function renderReloadOrAdd(): JSX.Element {
        if (!film.info.id) {
            return <SvgButton icon={Icon.Rotate} />;
        }
        if (!film.info.exist) {
            return <SvgButton icon={Icon.Plus} onClick={() => onAddMovieClick(film)} />;
        }
    }
}
