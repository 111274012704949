import './_button.scss';
import classNames from 'classnames';
import React, {MouseEvent, CSSProperties, JSX} from 'react';

interface ButtonParams {
    title: string | number;
    noMargin?: boolean;
    disabled?: boolean;
    className?: string;
    style?: CSSProperties;
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
    onDoubleClick?: (e: MouseEvent<HTMLButtonElement>) => void;
}

export function BigButton(params: ButtonParams): JSX.Element {
    return (
        <button
            style={params.style}
            className={classNames('mp-button', {[params.className]: !!params.className, 'no-margin': params.noMargin})}
            disabled={params.disabled}
            onClick={params.onClick}
            onDoubleClick={params.onDoubleClick}
        >
            {params.title}
        </button>
    );
}

export function Button(params: Omit<ButtonParams, 'noMargin'>): JSX.Element {
    return (
        <button
            style={params.style}
            className={classNames('mp-button-small', {[params.className]: !!params.className})}
            disabled={params.disabled}
            onClick={params.onClick}
            onDoubleClick={params.onDoubleClick}
        >
            {params.title}
        </button>
    );
}
