import {service} from '@mp/common/api';
import {isEmpty} from '@mp/common/utils/array';
import {Film, FilmInfo, FilmRating, Films, FwId, InfoType} from '../types';
import {loadFilmInfoService} from './loadFilmInfo.service';
import {loadFilmRatingService} from './loadFilmRating.service';

interface SearchFilmDto {
    total: string;
    searchHits: Array<FilmDto>;
}

interface FilmDto {
    id: string;
    type: string;
    matchedTitle: string;
    matchedLang: string;
    filmMainCast: Array<FilmMainCast>;
}

interface FilmMainCast {
    id: string;
    name: string;
}

export function searchMoviesService(searchPhrase: string, downloadInfoLimit: number, withRating: boolean = false): Promise<Films> {
    return service.get<SearchFilmDto>('searchFilm', {data: {searchPhrase: searchPhrase}, prefix: 'filmweb'}).then((response) => {
        const filteredHits: Array<FilmDto> = response.data.searchHits.filter((hit) =>
            [InfoType.Film, InfoType.Serial].includes(hit.type as InfoType)
        );

        const asyncServices: Array<Promise<unknown>> = [];
        filteredHits.forEach((rawHit, index) => {
            if (index < downloadInfoLimit) {
                asyncServices.push(loadFilmInfoService(Number(rawHit.id) as FwId));
            }
        });

        if (withRating) {
            filteredHits.forEach((rawHit, index) => {
                if (index < downloadInfoLimit) {
                    asyncServices.push(loadFilmRatingService(Number(rawHit.id) as FwId));
                }
            });
        }

        return Promise.all(asyncServices).then((results: Array<unknown>) => {
            const filmsInfo: Array<FilmInfo> = [];
            const filmsRating: Array<FilmRating> = [];
            results.forEach((result) => {
                if (result.hasOwnProperty('originalTitle')) {
                    filmsInfo.push(result as FilmInfo);
                } else if (result.hasOwnProperty('rate')) {
                    filmsRating.push(result as FilmRating);
                }
            });

            return resolveSearchMovieResult(filteredHits, filmsInfo, filmsRating);
        });
    });
}

function resolveSearchMovieResult(rawHitsDto: Array<FilmDto>, filmsInfo: Array<FilmInfo>, filmsRating: Array<FilmRating>): Films {
    if (isEmpty(rawHitsDto)) {
        return null;
    }

    return rawHitsDto.map(
        (dto): Film => ({
            fid: Number(dto.id) as FwId,
            matchedLang: dto.matchedLang,
            matchedTitle: dto.matchedTitle,
            filmMainCast: dto.filmMainCast?.map((fmc) => ({id: Number(fmc.id), name: fmc.name})) ?? [],
            type: dto.type as InfoType,
            info: filmsInfo.find((info) => info.id === Number(dto.id)) ?? ({} as FilmInfo),
            rating: filmsRating.find((rating) => rating.id === Number(dto.id)) ?? ({} as FilmRating)
        })
    );
}
