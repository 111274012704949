import {CommonContext} from '@mp/common/context';
import {Icon, SvgButton} from '@mp/common/svg';
import {toDateString} from '@mp/common/utils/converters';
import {convertToCurrency} from '@mp/common/utils/number';
import {join} from '@mp/common/utils/string';
import classNames from 'classnames';
import React, {JSX, useContext, useMemo} from 'react';
import {GiftsPageContext} from '../context/GiftsPage.context';
import {addGiftService, updateGiftStatusService} from '../services/gifts.services';
import {Gift, GiftDto, GiftStatus, GroupedGifts, Person} from '../types';
import {getAllGiftStatuses, getStatusColor, getStatusName} from '../utils/giftStatus';
import css from './Gift.module.scss';

export function GiftComponent(props: {groupedGifts: GroupedGifts; onEditClick: (gift: Gift) => void}): JSX.Element {
    const {groupedGifts, onEditClick} = props;
    const {people, reloadData} = useContext(GiftsPageContext);
    const {isEditMode} = useContext(CommonContext).context;

    const personName: string = useMemo((): string => {
        const person: Person = people.find(({id}) => id === groupedGifts.personId) ?? ({} as Person);
        return join(person.firstName, person.lastName);
    }, [people]);

    const handleNewGiftClick = (): void => {
        const giftToAdd: GiftDto = {personId: groupedGifts.personId, date: toDateString(groupedGifts.date)} as GiftDto;
        addGiftService(giftToAdd).then(({success}) => {
            if (success) {
                reloadData();
            }
        });
    };

    return (
        <div className={css.groupItem}>
            <div className={`${css.row} ${css.groupItemH}`}>
                <div>
                    {isEditMode && <SvgButton className={css.icon} icon={Icon.Plus} onClick={handleNewGiftClick} />}
                    {personName}
                </div>
                <div>{toDateString(groupedGifts.date)}</div>
            </div>

            {groupedGifts.gifts.map((entry) => renderGift(entry, isEditMode, onEditClick))}
        </div>
    );
}

function renderGift(gift: Gift, isEditMode: boolean, onEditClick: (gift: Gift) => void): JSX.Element {
    const {id, name, description, status, price} = gift;

    const priceString: string = price ? convertToCurrency(price, true) : '';

    return (
        <div key={id} className={css.gift}>
            <div className={css.row}>
                <div>
                    {isEditMode && <SvgButton className={css.icon} icon={Icon.Pencil} onClick={() => onEditClick(gift)} />}
                    {name || '-'}
                </div>
                <div className={css.price}>
                    <span>{priceString}</span>
                    <i className={css.statusDot} style={{backgroundColor: getStatusColor(status)}} />
                </div>
            </div>
            <div className={css.giftD}>{description}</div>
            {isEditMode && <GiftStatusComponent gift={gift} isEditMode={isEditMode} />}
        </div>
    );
}

function GiftStatusComponent({gift, isEditMode}: {gift: Gift; isEditMode: boolean}): JSX.Element {
    const {reloadData} = useContext(GiftsPageContext);
    const handleStatusClick = (newStatus: GiftStatus): void => {
        if (isEditMode) {
            updateGiftStatusService({id: gift.id, status: newStatus}).then(({success}) => {
                if (success) {
                    reloadData();
                }
            });
        }
    };

    return (
        <div className={css.statusContainer}>
            {isEditMode ? (
                getAllGiftStatuses().map((gStatus) => (
                    <div
                        key={gStatus}
                        className={classNames(css.status, {[css.statusSelected]: gStatus === gift.status, [css.statusEdit]: isEditMode})}
                        onDoubleClick={() => handleStatusClick(gStatus)}
                    >
                        {getStatusName(gStatus)}
                    </div>
                ))
            ) : (
                <div>{getStatusName(gift.status)}</div>
            )}
        </div>
    );
}
