import {Blink} from '@mp/common/components/blink/ui/Blink';
import {Modal} from '@mp/common/modals/Modal';
import {Icon, SvgButton} from '@mp/common/svg';
import {isNotEmpty} from '@mp/common/utils/array';
import {createLinkToSearch} from '@mp/filmweb/utils/link';
import React, {JSX} from 'react';
import {Link} from 'react-router-dom';
import {MovieInfo, Schedule} from '../types';
import {getShareLink} from '../utils/getMessengerShareLink';

export interface MovieInfoSingleSchedule {
    movieInfo: MovieInfo;
    schedule?: Schedule;
}

interface ScheduleModalParams {
    selectedMovieInfo: MovieInfoSingleSchedule;
    date: Date;
    handleClose: () => void;
}

const DESCRIPTION: string = 'description';

export function ScheduleModal({selectedMovieInfo, date, handleClose}: ScheduleModalParams): JSX.Element {
    const {movieInfo, schedule} = selectedMovieInfo;
    const {title, mkId, synopsisShort, director, cast, runningTime} = movieInfo;
    const {startTime, endTime, filmStartTime, bookingLink, screenName} = schedule ?? {};

    return (
        <Modal handleClose={handleClose} title={title}>
            <div className="schedule-modal">
                <div className="screen">{screenName}</div>
                {startTime && endTime && (
                    <div className="schedule">
                        <span>{`${startTime} - ${endTime}`}</span>
                        <a href={getShareLink({date, mkId, startTime})}>
                            <SvgButton icon={Icon.Share} />
                        </a>
                    </div>
                )}
                <div className="buy-ticket">
                    <a href={null}>
                        <Blink text="Wziąć słomki" />
                    </a>
                </div>
                {bookingLink && (
                    <div className="buy-ticket">
                        <a href={bookingLink} target="_blank" rel="noopener noreferrer">
                            <span>KUP BILET</span>
                        </a>
                    </div>
                )}
                {filmStartTime && <div>Start filmu</div>}
                {filmStartTime && <div className={DESCRIPTION}>{filmStartTime}</div>}
                {runningTime > 0 && <div>Czas</div>}
                {runningTime > 0 && <div className={DESCRIPTION}>{runningTime} min.</div>}
                {director && <div>Reżyseria</div>}
                {director && <div className={DESCRIPTION}>{director}</div>}
                {isNotEmpty(cast) && (
                    <div className="cast">
                        <div>Obsada</div>
                        <div className={DESCRIPTION}>
                            {cast.map((person) => (
                                <Link key={person} to={createLinkToSearch(person)} target="_blank">
                                    {person}
                                </Link>
                            ))}
                        </div>
                    </div>
                )}
                {synopsisShort && <div>Fabuła</div>}
                {synopsisShort && <div className={DESCRIPTION}>{synopsisShort}</div>}
            </div>
        </Modal>
    );
}
