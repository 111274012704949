import React, {JSX, useRef, useState} from 'react';
import {useWasInViewport} from '../../../hooks/wasInViewPort';

interface ImageProps {
    src: string;
    loadingSrc?: string;
    errorSrc?: string;
    alt?: string;
    onError?: () => void;
}
export function Image(props: ImageProps): JSX.Element {
    const {src, alt, loadingSrc, errorSrc, onError} = props;
    const [isError, setIsError] = useState(false);
    const ref = useRef(null);
    const isInViewport = useWasInViewport(ref);

    if (isError && errorSrc) {
        return <img src={errorSrc} alt="error-src" />;
    }

    return (
        <img
            ref={ref}
            src={isInViewport ? src : loadingSrc}
            alt={alt ?? src}
            onError={() => {
                if (onError) {
                    onError();
                } else {
                    setIsError(true);
                }
            }}
        />
    );
}
