import React, {JSX} from 'react';

export enum DotNumber {
    ONE,
    TWO,
    THREE
}

interface ClueIconProps {
    dotNumber: DotNumber;
    color?: string;
}

export class ClueIcon extends React.Component<ClueIconProps> {
    private circleContainer: JSX.Element = null;
    private color: string = null;

    constructor(props: ClueIconProps) {
        super(props);

        this.color = this.props.color ? this.props.color : 'black';

        switch (this.props.dotNumber) {
            case DotNumber.THREE:
                this.circleContainer = (
                    <span className="circle-container n-3">
                        <span className="circle-1" style={{backgroundColor: this.color}} />
                        <span className="circle-2" style={{backgroundColor: this.color}} />
                        <span className="circle-3" style={{backgroundColor: this.color}} />
                    </span>
                );
                break;
            case DotNumber.TWO:
                this.circleContainer = (
                    <span className="circle-container n-2">
                        <span className="circle-1" style={{backgroundColor: this.color}} />
                        <span className="circle-2" style={{backgroundColor: this.color}} />
                    </span>
                );
                break;
            case DotNumber.ONE:
                this.circleContainer = (
                    <span className="circle-container n-1">
                        <span className="circle-1" style={{backgroundColor: this.color}} />
                    </span>
                );
                break;
            default:
                throw new Error('Unknown value: ' + this.props.dotNumber);
        }
    }

    render() {
        return <clue-icon>{this.circleContainer}</clue-icon>;
    }
}
