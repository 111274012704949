export interface Person {
    id: number;
    birthday: Date;
    firstName: string;
    lastName: string;
}

export type GiftDto = Omit<Gift, 'date' | 'status' | 'timestamp'> & {date: string; status: string};

export interface GroupedGifts extends Pick<Gift, 'personId' | 'date'> {
    gifts: Gift[];
}

export interface Gift {
    id: number;
    personId: number;
    date: Date;
    timestamp: number;
    name: string | null;
    description: string | null;
    status: GiftStatus | null;
    price: number | null;
}

export interface GiftGroup {
    id: number;
    name: string;
    peopleIds: Array<number>;
}

export enum GiftStatus {
    ORDERED = 'ordered',
    BOUGHT = 'bought',
    DELIVERED = 'delivered',
    RECEIVED = 'received'
}
