import './_calendarPage.scss';
import {useNumberQueryParams, useQueryParams} from '@mp/common/hooks/useQueryParams';
import {isEmpty} from '@mp/common/utils/array';
import {MONTH_PARAM, Router, YEAR_PARAM} from '@mp/route';
import React, {JSX, useEffect, useState} from 'react';
import {CalendarDate} from '../../components/calendar/types';
import {CalendarComponent} from '../../components/calendar/ui/Calendar';
import {TabsNavigation} from '../../components/tabs-navigation/ui/TabsNavigation';
import {BirthdayPage} from './ui/birthdayPage';

const MP_CALENDAR_PAGE = 'mp-calendar-page';

enum View {
    Anniversaries = 'anniversaries'
}

export interface CalendarPageState {
    calendarDates: Array<CalendarDate>;
    displayedDate: Date;
}

export interface CalendarPageProps {
    state: CalendarPageState;
    year: number;
    month: number;
}

export function CalendarPage(props: Pick<CalendarPageProps, 'state'>): JSX.Element {
    const [state, setState] = useState<CalendarPageState>(null);
    const year: number = useNumberQueryParams(YEAR_PARAM);
    const month: number = useNumberQueryParams(MONTH_PARAM);
    const view: View = useQueryParams('view') as View;

    useEffect(() => {
        setState(props.state);
    }, []);

    if (isEmpty(state?.calendarDates)) {
        return null;
    }

    return (
        <>
            <TabsNavigation
                tabs={[
                    {name: 'Kalendarz', url: Router.getUrlToCalendarPage({year, month}), selected: view == null},
                    {
                        name: 'Rocznice',
                        url: Router.getUrlToCalendarPage({year, month, view: View.Anniversaries}),
                        selected: view === View.Anniversaries
                    }
                ]}
            />
            {view === View.Anniversaries ? (
                <BirthdayPage calendarDates={state.calendarDates} />
            ) : (
                <div className={MP_CALENDAR_PAGE}>
                    <CalendarComponent calendarDates={state.calendarDates} year={year} month={month} />
                </div>
            )}
        </>
    );
}
