import './_recipeEditPage.scss';
import {TextInput} from '@mp/common/components/text-input/ui/TextInput';
import {FormsModal} from '@mp/common/modals/FormsModal';
import React, {JSX, useContext, useRef, useState} from 'react';
import {DEFAULT_INGREDIENT_SECTION} from '../consts';
import {RecipesPageContext} from '../context/RecipesPage.context';
import {addIngredientService, editIngredientService, editRecipeService} from '../services';
import {Ingredient, Recipe, RecipeCategory} from '../types';
import {IngredientEditRow} from './IngredientEditRow';
import {RecipeDescription} from './RecipeDescription';
import {RecipeHeader} from './RecipeHeader';
import {getInputsData} from './utils/getInputsData';

const RECIPE_EDIT_PAGE: string = 'recipe-edit-page';

interface RecipeEditPageProps {
    recipe: Recipe;
    categories: Array<RecipeCategory>;
    ingredients: Array<Ingredient>;
}

export function RecipeEditPage(props: RecipeEditPageProps): JSX.Element {
    const {reloadData} = useContext(RecipesPageContext);

    const {recipe, ingredients, categories} = props;
    const ingredientSectionInputRef: React.RefObject<HTMLInputElement> = useRef(null);
    const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);

    return (
        <div className={RECIPE_EDIT_PAGE}>
            <RecipeHeader recipe={recipe} onEdit={() => setIsEditModalVisible(true)} />
            <div className={`${RECIPE_EDIT_PAGE}-ingredient-section`}>
                <h4>Sekcja do której będą aktualizowane składniki</h4>
                <TextInput
                    reference={ingredientSectionInputRef}
                    placeholder="Sekcja składników"
                    defaultValue={DEFAULT_INGREDIENT_SECTION}
                />
            </div>
            <IngredientListEdit recipeId={recipe.id} ingredients={ingredients} sectionInputRef={ingredientSectionInputRef} />
            <RecipeDescription description={recipe.description} />
            <div style={{height: '120px'}} /> {/* space for suggestion list */}
            {isEditModalVisible && <EditRecipeModal />}
        </div>
    );

    function EditRecipeModal(): JSX.Element {
        return (
            <FormsModal<Recipe>
                mode="update"
                title="Edytuj przepis"
                inputs={getInputsData(categories, recipe)}
                onSendForm={(data) =>
                    editRecipeService(data as Recipe).then(() => {
                        setIsEditModalVisible(false);
                        reloadData();
                    })
                }
                handleClose={() => setIsEditModalVisible(false)}
            />
        );
    }
}

function IngredientListEdit({
    recipeId,
    sectionInputRef,
    ingredients
}: {
    recipeId: number;
    sectionInputRef: React.RefObject<HTMLInputElement>;
    ingredients: Array<Ingredient>;
}): Array<JSX.Element> {
    const {reloadData} = useContext(RecipesPageContext);
    let latestSection: string = null;

    const elements: Array<JSX.Element> = [
        <IngredientEditRow
            key="add-ingr"
            recipeId={recipeId}
            ingredient={null}
            sectionInputRef={sectionInputRef}
            onAction={(data) => addIngredientService(data).then(({success}) => (success ? reloadData() : null))}
        />
    ];

    ingredients.forEach((ingredient) => {
        if (latestSection !== ingredient.section) {
            elements.push(
                <h4 onClick={() => (sectionInputRef.current.value = ingredient.section)} key={ingredient.section}>
                    {ingredient.section}
                </h4>
            );
            latestSection = ingredient.section;
        }

        elements.push(
            <IngredientEditRow
                key={ingredient.section + ingredient.id}
                recipeId={recipeId}
                ingredient={ingredient}
                sectionInputRef={sectionInputRef}
                onAction={(data) => editIngredientService(data).then(({success}) => (success ? reloadData() : null))}
            />
        );
    });

    return elements;
}
