import './_textInput.scss';
import classNames from 'classnames';
import React, {ChangeEvent, CSSProperties, JSX, Ref, useMemo, useState} from 'react';
import {Icon, SvgButton} from '../../../svg';

interface InputParams {
    clearButton?: boolean;
    title?: string;
    name?: string;
    placeholder?: string;
    defaultValue?: string | number;
    maxLength?: number;
    autoFocus?: boolean;
    spellCheck?: boolean;
    inputMode?: 'numeric' | 'search' | 'text' | 'email' | 'tel' | 'url' | 'none' | 'decimal';
    pattern?: string;
    disabled?: boolean;
    autoCapitalize?: 'on' | 'none';
    isPassword?: boolean;
    style?: CSSProperties;
    reference?: Ref<HTMLInputElement>;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    onClear?: () => void;
}

export function TextInput(params: InputParams): JSX.Element {
    const {title, clearButton, defaultValue, onChange, onClear} = params;
    const [isClearVisible, setIsClearVisible] = useState<boolean>(Boolean(defaultValue));

    const id: string = useMemo(() => `text_input_${Math.random().toString(36)}`, []);

    const handleClear = () => {
        const el: HTMLInputElement = document.getElementById(id) as HTMLInputElement;
        if (el) {
            el.value = '';
            el.focus();
            setIsClearVisible(false);

            if (onClear) {
                onClear();
            }
        }
    };

    return (
        <div className={classNames('mp-text-input', {'with-clear-button': clearButton})} data-name={params.name}>
            {title && <div className="mp-text-input-title">{title}</div>}
            {clearButton && isClearVisible && <SvgButton className="mp-text-input-clear" icon={Icon.Xmark} onClick={handleClear} />}
            <input
                id={id}
                ref={params.reference}
                type={params.isPassword ? 'password' : 'text'}
                inputMode={params.inputMode}
                pattern={params.pattern}
                spellCheck={params.spellCheck}
                defaultValue={params.defaultValue}
                disabled={params.disabled}
                style={params.style}
                maxLength={params.maxLength}
                name={params.name}
                autoFocus={params.autoFocus}
                placeholder={params.placeholder}
                autoCapitalize={params.autoCapitalize ?? 'none'}
                onChange={(e) => {
                    setIsClearVisible(Boolean(e.currentTarget.value));
                    if (onChange) {
                        onChange(e);
                    }
                }}
            />
        </div>
    );
}
