import './_winesRankingPage.scss';
import {SimpleToolBar} from '@mp/common/components/tool-bar';
import {FormsModal} from '@mp/common/modals/FormsModal';
import React, {JSX, useEffect, useState} from 'react';
import {Loading} from '../../../components/loading';
import {addWineService, editWineService, loadWinesService, WinesServiceResult} from '../services/wine.service';
import {Wine} from '../types';
import {getInputsData} from '../utils/getInputsData';
import {WineItem} from './WineItem';

export function WinesRankingPage(): JSX.Element {
    const [winesData, setWinesData] = useState<WinesServiceResult>(null);
    const [isAddView, setIsAddView] = useState<boolean>(null);
    const [selectedWine, setSelectedWine] = useState<Wine>(null);

    useEffect(() => initData(), []);

    if (winesData == null) {
        return <Loading />;
    }

    return (
        <div className="mp-wines-ranking">
            <SimpleToolBar name="Ranking win" onAddClick={() => setIsAddView(true)} />
            <div className="mp-wines-ranking-content">
                {winesData.wines?.map((wine: Wine) => (
                    <WineItem key={wine.id} wine={wine} maxRating={winesData.maxRating} onImageDoubleClick={() => setSelectedWine(wine)} />
                ))}
                {isAddView && (
                    <FormsModal
                        title="Dodaj wino"
                        inputs={getInputsData(winesData)}
                        mode="add"
                        handleClose={() => setIsAddView(null)}
                        onSendForm={(object) => addWineService(object).then(({success}) => success && initData())}
                    />
                )}
                {selectedWine && (
                    <FormsModal
                        title="Edytuj wino"
                        inputs={getInputsData(winesData, selectedWine)}
                        mode="update"
                        handleClose={() => setSelectedWine(null)}
                        onSendForm={(object) => editWineService(object).then(({success}) => success && initData())}
                    />
                )}
            </div>
        </div>
    );

    function initData(): void {
        loadWinesService().then((data) => {
            setWinesData(data);
            setSelectedWine(null);
            setIsAddView(null);
        });
    }
}
