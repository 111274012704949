import classNames from 'classnames';
import React, {ChangeEvent, CSSProperties, JSX, LegacyRef} from 'react';

interface DateInputParams {
    defaultValue?: string | number;
    title?: string;
    name?: string;
    disabled?: boolean;
    className?: string;
    style?: CSSProperties;
    reference?: LegacyRef<HTMLInputElement>;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export function DateInput(params: DateInputParams): JSX.Element {
    const {title} = params;
    return (
        <div className={classNames('mp-date-input', {[params.className]: !!params.className})}>
            {title && <div className="mp-date-input-title">{title}</div>}
            <input
                type="date"
                ref={params.reference}
                defaultValue={params.defaultValue}
                disabled={params.disabled}
                style={params.style}
                name={params.name}
                onChange={params.onChange}
            />
        </div>
    );
}
