import {Icon, SvgButton} from '@mp/common/svg';
import React, {JSX, useEffect, useRef, useState} from 'react';

interface SpeechRecognitionProps {
    onSpeechEnd: (transcript: string) => void;
}

export function SpeechRecognition({onSpeechEnd}: SpeechRecognitionProps): JSX.Element {
    const speechRecognitionRef: React.RefObject<SpeechRecognition> = useRef(null);
    const [isListening, setIsListening] = useState<boolean>(false);

    useEffect(() => {
        if ('webkitSpeechRecognition' in window) {
            const {webkitSpeechRecognition} = window;
            speechRecognitionRef.current = new webkitSpeechRecognition();
            speechRecognitionRef.current.lang = 'pl-PL';
            speechRecognitionRef.current.onresult = (event) => {
                onSpeechEnd(event.results[0][0].transcript);
            };
            speechRecognitionRef.current.onstart = () => setIsListening(true);
            speechRecognitionRef.current.onend = () => setIsListening(false);
        }
    }, []);

    if (!('webkitSpeechRecognition' in window)) {
        return null;
    }

    function handleTouchStart(): void {
        speechRecognitionRef.current.start();
    }

    return (
        <div className="darts-speech-recognition">
            <SvgButton
                icon={Icon.Microphone}
                onClick={handleTouchStart}
                style={{background: isListening ? 'green' : '#333', borderRight: 'solid 1px gray'}}
            />
        </div>
    );
}
