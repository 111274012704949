import './workHoursTimeOff.scss';
import {Button} from '@mp/common/components/button/ui/Button';
import {Calendar} from '@mp/common/components/calendar/ui/Calendar';
import {SelectOption} from '@mp/common/components/form-input/types';
import {ConfirmModal} from '@mp/common/modals/ConfirmModal';
import {firstElement, isEmpty, isNotEmpty} from '@mp/common/utils/array';
import {toDateString} from '@mp/common/utils/converters';
import {getCurrentDateString} from '@mp/common/utils/date';
import React, {CSSProperties, JSX, RefObject, useEffect, useRef, useState} from 'react';
import {Loading} from '../../../components/loading';
import {addDayOffService, deleteDayOffService, loadDaysOffService} from '../services/daysOff.service';
import {DayOff, WorkDayType} from '../types';
import {getSelectOptions, resolveWorkTypeColor, resolveWorkTypeName} from '../utils/workDayType';

type DayOffMap = Map<number, Array<DayOff>>;
type DayOffToAddMap = Map<string, {dateString: string; type: WorkDayType}>;

const selectOptions: Array<SelectOption> = getSelectOptions().filter((option) => option.value);

export function WorkHoursTimeOff({employerId}: {employerId: number}): JSX.Element {
    const [daysOffMap, setDaysOffMap] = useState<DayOffMap>(null);
    const [forceReload, setForceReload] = useState<Array<unknown>>([]);
    const [dateToDelete, setDateToDelete] = useState<DayOff>(null);
    const [year, setYear] = useState<number>(new Date().getFullYear());
    const allowedDays: RefObject<number> = useRef(null);
    const usedDaysOff: RefObject<number> = useRef(null);
    const availableYears: RefObject<Array<number>> = useRef(null);
    const [daysOffToAdd, setDaysOffToAdd] = useState<DayOffToAddMap>(new Map());

    useEffect(() => {
        loadDaysOffService(employerId, year).then((result) => {
            const monthToDatesMap: DayOffMap = new Map();
            result.daysOff.forEach((entry) => {
                const month: number = entry.date.getMonth();
                const datesOff: Array<DayOff> = monthToDatesMap.get(month) ?? [];
                datesOff.push(entry);
                monthToDatesMap.set(month, datesOff);
            });
            allowedDays.current = result.allowedDays;
            usedDaysOff.current = result.usedDaysOff;
            availableYears.current = result.availableYears;
            setDaysOffMap(monthToDatesMap);
        });
    }, [employerId, forceReload, year]);

    if (daysOffMap == null) {
        return <Loading />;
    }

    const reload = (_year?: number): void => {
        setForceReload([]);
        setDaysOffMap(null);
        setDateToDelete(null);
        setDaysOffToAdd(new Map());
        setYear(_year ?? year);
    };

    const daysOffLeft: number = allowedDays.current - usedDaysOff.current - daysOffToAdd.size;

    const onDateClickFn = (date: Date, dates: Array<DayOff>): void => {
        const dateString: string = toDateString(date);
        if (isEmpty(dates)) {
            if (daysOffToAdd.has(dateString)) {
                daysOffToAdd.delete(dateString);
            } else if (daysOffLeft > 0) {
                daysOffToAdd.set(dateString, {dateString, type: WorkDayType.PTO});
            }
            setDaysOffToAdd(new Map(daysOffToAdd));
        } else {
            setDateToDelete(firstElement(dates));
        }
    };

    const renderCellFn = (cellDate: Date, dates: Array<DayOff>): JSX.Element => {
        const {type} = firstElement(dates) ?? {};
        const isToAdd: boolean = daysOffToAdd.has(toDateString(cellDate));
        const currentDay: boolean = toDateString(cellDate) === getCurrentDateString();
        const backgroundColor: string = resolveWorkTypeColor(type);

        const styles: CSSProperties = {
            fontWeight: isToAdd ? 'bold' : null
        };

        if (backgroundColor) {
            styles.backgroundColor = backgroundColor;
        }

        if (type === WorkDayType.HalfPTO) {
            styles.background = `linear-gradient(to left bottom, white 50%, ${backgroundColor} 50%)`;
        }

        return (
            <div className={currentDay ? 'today' : null} style={styles}>
                {cellDate.getDate()}
            </div>
        );
    };

    const datesArrayFn = (): Array<Date> => {
        const currentMonth: number = new Date().getMonth();
        const monthToDate = (month: number): Date => new Date(year, month, currentMonth === month ? new Date().getDate() : 1);
        return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(monthToDate);
    };

    const getHeader = () => `Pozostało dni urlopu: ${daysOffLeft} z ${allowedDays.current}`;

    return (
        <div className="time-off">
            <div className="time-off-header">
                {isNotEmpty(availableYears.current) && (
                    <>
                        <select defaultValue={year} onChange={(event) => reload(Number(event.currentTarget.value))}>
                            {availableYears.current.map((_year) => (
                                <option key={_year} value={_year}>
                                    {_year}
                                </option>
                            ))}
                        </select>
                        <div>{getHeader()}</div>
                    </>
                )}
            </div>
            <div className="calendars-container">
                {datesArrayFn().map((date) => (
                    <Calendar
                        key={date.getMonth()}
                        currentDate={date}
                        displayDates={daysOffMap.get(date.getMonth())}
                        onDateClick={onDateClickFn}
                        renderCellFn={renderCellFn}
                    />
                ))}
            </div>
            {Array.from(daysOffToAdd.values()).map((entry) => (
                <div key={entry.dateString} className="time-off-to-add">
                    <span>{entry.dateString}</span>
                    <select
                        defaultValue={entry.type}
                        onChange={(event) => {
                            daysOffToAdd.get(entry.dateString).type = event.currentTarget.value as WorkDayType;
                            setDaysOffToAdd(daysOffToAdd);
                        }}
                    >
                        {selectOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.name}
                            </option>
                        ))}
                    </select>
                    <Button
                        title="Usuń"
                        onClick={() => {
                            daysOffToAdd.delete(entry.dateString);
                            setDaysOffToAdd(new Map(daysOffToAdd));
                        }}
                    />
                </div>
            ))}
            {daysOffToAdd.size > 0 && (
                <Button
                    title="Zapisz"
                    onClick={() => {
                        const promises: Array<Promise<unknown>> = Array.from(daysOffToAdd.values()).map(({dateString, type}) =>
                            addDayOffService(dateString, type, employerId)
                        );
                        Promise.all(promises).then(() => reload());
                    }}
                />
            )}
            {dateToDelete && (
                <ConfirmModal
                    title="Usuwanie"
                    handleConfirm={() => deleteDayOffService(dateToDelete.id).then(({success}) => success && reload())}
                    handleClose={() => setDateToDelete(null)}
                    question={`Czy usunięć ${resolveWorkTypeName(dateToDelete.type).toLowerCase()} ${dateToDelete.dateString} `}
                />
            )}
        </div>
    );
}
