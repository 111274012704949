import {useNumberQueryParams, useQueryParams} from '@mp/common/hooks/useQueryParams';
import {toDateString} from '@mp/common/utils/converters';
import {numberWithUnit} from '@mp/common/utils/unit';
import {ExpensesTab, Router} from '@mp/route';
import React, {JSX} from 'react';
import {useNavigate} from 'react-router-dom';
import {Expense, ExpenseCategory} from '../types';
import {getNameByTypeId} from '../utils/getNameByTypeId';
import {ExpensesSummary} from './ExpensesSummary';
import styles from './YearExpensesPage.module.scss';

interface YearExpensesPageProps {
    expenses: Array<Expense>;
    expenseCategories: Array<ExpenseCategory>;
}

export function YearExpensesPage(props: YearExpensesPageProps): JSX.Element {
    const {expenses, expenseCategories} = props;
    const year = useQueryParams('year');
    const month = useQueryParams('month');
    const categoryId = useNumberQueryParams('category');

    const navigate = useNavigate();

    if (categoryId) {
        const categoryName: string = getNameByTypeId(categoryId, expenseCategories);
        const categoryExpenses: Array<Expense> = expenses.filter((f) => f.typeId === categoryId);

        return (
            <div className={styles.page}>
                <div>{categoryName}</div>
                <div className={styles.rowsContainer}>
                    {categoryExpenses.map((entry) => (
                        <div key={entry.id} className={styles.row}>
                            <div>{numberWithUnit(entry.value, 'zł', 2)}</div>
                            <div>{entry.description}</div>
                            <div style={{width: '100px'}}>{toDateString(new Date(entry.date), 'dd month')}</div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    return (
        <ExpensesSummary
            expenses={expenses}
            expenseCategories={expenseCategories}
            title={year}
            onExpenseCategoryClick={(x) =>
                navigate(Router.getUrlToExpensesPage(ExpensesTab.YearSummary, new Date(`${year}-${month}-1`), String(x)))
            }
        />
    );
}
