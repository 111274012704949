import React, {JSX} from 'react';
import {MkId, Schedule} from '../types';
import {resolveMovieTimeId} from '../utils/resolveMovieTimeId';

export function ScheduleTile(props: {mkId: MkId; schedule: Schedule; onClick: (schedule: Schedule) => void}): JSX.Element {
    const {mkId, schedule, onClick} = props;
    const {startTime, versionTitle} = schedule;

    return (
        <button data-custom-stime={resolveMovieTimeId({mkId, startTime})} className="schedule-tile" onClick={() => onClick(schedule)}>
            <div className="schedule-tile-time">{startTime}</div>
            <div className="schedule-tile-version">{versionTitle}</div>
        </button>
    );
}
