import {RouteName} from '@mp/route';
import classNames from 'classnames';
import React, {JSX} from 'react';
import {useLocation} from 'react-router';
import {Link} from 'react-router-dom';
import css from './TabAnchor.module.scss';

interface TabAnchorProps {
    name: string;
    href: RouteName;
}

export function TabAnchor(props: TabAnchorProps): JSX.Element {
    const {name, href} = props;
    const location = useLocation();

    return (
        <Link to={href} className={classNames(css.tab, {[css.selected]: location.pathname === href})}>
            {name}
        </Link>
    );
}
