import {Image} from '@mp/common/components/image/ui/Image';
import {RatingStars} from '@mp/common/components/rating-star/RatingStars';
import classNames from 'classnames';
import React, {JSX} from 'react';
import {isAdmin} from '../../../global';
import wineBottle from '../assets/wine-bottle.png';
import {t} from '../i18n';
import {Wine} from '../types';

interface WineItemProps {
    wine: Wine;
    maxRating: number;
    onImageDoubleClick: (wine: Wine) => void;
}

export function WineItem(props: WineItemProps): JSX.Element {
    const {wine, maxRating, onImageDoubleClick} = props;

    return (
        <div className={classNames('wine-detail', {'wine-detail-gray': wine.rankingValue == null})} key={wine.id}>
            <div
                style={{filter: getStyleFilter(wine), opacity: getOpacityFilter(wine)}}
                className="wine-detail-left"
                onDoubleClick={() => onImageDoubleClick(wine)}
            >
                <Image src={wine.imageLink || wineBottle} loadingSrc={wineBottle} alt="wino" />
            </div>
            <div className="wine-detail-right">
                <a href={`https://www.google.pl/search?q=${wine.name}`}>{wine.name}</a>
                {isAdmin() && renderRow(t.price, wine.price ? wine.price.toFixed(2) + ' zł' : null)}
                {renderRow(t.originCountry, wine.country)}
                {renderRow(t.color, wine.color)}
                {renderRow(t.vineStock, wine.vineStock, true)}
                {renderRow(t.dryness, wine.dryness)}
                {renderRow(t.type, wine.type)}
                {renderRow(t.strength, wine.strength ? wine.strength + '%' : null)}
                {renderRow(t.rankingValue, wine.rankingValue != null ? <RatingStars rate={wine.rankingValue} maxRate={maxRating} /> : null)}
            </div>
        </div>
    );
}

function getStyleFilter(wine: Wine): string {
    return wine.rankingValue ? null : 'grayscale(1)';
}

function getOpacityFilter(wine: Wine): string {
    return wine.rankingValue ? null : '0.2';
}

function renderRow(key: string, value: string | JSX.Element, withTitle: boolean = false): JSX.Element {
    return (
        <div className="wine-detail-right-row">
            <span>{key}:</span>
            <span className={classNames({'wine-detail-right-row-string-value': typeof value === 'string'})} title={resolveTitle()}>
                {value || '-'}
            </span>
        </div>
    );

    function resolveTitle(): string {
        return withTitle && typeof value === 'string' ? value : '';
    }
}
