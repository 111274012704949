import './_recipeViewPage.scss';
import {TextInput} from '@mp/common/components/text-input/ui/TextInput';
import {isNotEmpty} from '@mp/common/utils/array';
import React, {JSX, useEffect, useRef, useState} from 'react';
import {Ingredient, Recipe} from '../types';
import {IngredientRow} from './IngredientRow';
import {RecipeDescription} from './RecipeDescription';
import {RecipeHeader} from './RecipeHeader';

const RECIPE_VIEW_PAGE: string = 'recipe-view-page';

interface RecipeViewProps {
    recipe: Recipe;
    ingredients: Array<Ingredient>;
}

type IngredientSectionMap = Map<string, Array<Ingredient>>;

export function RecipeViewPage({recipe, ingredients}: RecipeViewProps): JSX.Element {
    const [portions, setPortions] = useState<number>(1);
    const [ingredientsSectionsMap, setIngredientsSectionsMap] = useState<IngredientSectionMap>(new Map<string, Array<Ingredient>>());

    useEffect(() => {
        if (isNotEmpty(ingredients)) {
            const map: IngredientSectionMap = new Map<string, Array<Ingredient>>();

            ingredients.forEach((ingredient: Ingredient) => {
                const foundSection: Array<Ingredient> = map.get(ingredient.section);

                if (foundSection) {
                    foundSection.push(ingredient);
                } else {
                    map.set(ingredient.section, [ingredient]);
                }
            });
            setIngredientsSectionsMap(map);
        }
    }, []);

    return (
        <div className={RECIPE_VIEW_PAGE}>
            <RecipeHeader recipe={recipe} multiplier={portions} />
            <PortionsChanger onChange={setPortions} />
            <IngredientSections />
            <RecipeDescription description={recipe.description} />
        </div>
    );

    function IngredientSections(): JSX.Element {
        const ingredientsSections: Array<JSX.Element> = [];
        ingredientsSectionsMap.forEach((ing, section) => {
            ingredientsSections.push(
                <div key={section}>
                    <h3>{section}</h3>
                    {ing.map((ingredient: Ingredient) => (
                        <IngredientRow key={ingredient.id} ingredient={ingredient} portions={portions} />
                    ))}
                </div>
            );
        });

        return <>{ingredientsSections}</>;
    }
}

function PortionsChanger({onChange}: {onChange: (value) => void}): JSX.Element {
    const portionChangerElementValue = useRef(1);

    return (
        <div className={`${RECIPE_VIEW_PAGE}-portions`}>
            <TextInput
                defaultValue={portionChangerElementValue.current}
                maxLength={4}
                inputMode="decimal"
                onChange={(el) => {
                    const value = el.currentTarget.value.replaceAll(',', '.');
                    const floatValue: number = Math.abs(parseFloat(value) || 1);

                    portionChangerElementValue.current = floatValue;
                    onChange(floatValue);
                }}
            />
        </div>
    );
}
