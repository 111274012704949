import './_fileInput.scss';
import {SvgIcon, Icon} from '@mp/common/svg';
import classNames from 'classnames';
import React, {ChangeEvent, JSX, LegacyRef} from 'react';

interface DateInputParams {
    title?: string;
    accept?: string;
    disabled?: boolean;
    reference?: LegacyRef<HTMLInputElement>;
    uploadPercentage?: number;
    multiple?: boolean;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export function FileInput(params: DateInputParams): JSX.Element {
    const {title, uploadPercentage, multiple = false} = params;
    return (
        <div className={classNames('mp-file-input')}>
            {title && <div className="mp-file-input-title">{title}</div>}
            <input
                type="file"
                ref={params.reference}
                disabled={params.disabled}
                accept={params.accept}
                onChange={params.onChange}
                multiple={multiple}
            />
            <SvgIcon icon={Icon.CloudArrowUp} />
            {uploadPercentage != null && (
                <div className="mp-file-input-uploading-progress">
                    <div style={{width: `${uploadPercentage}%`}}>
                        <span>{`${uploadPercentage}%`}</span>
                    </div>
                </div>
            )}
        </div>
    );
}
