import {Icon, SvgButton} from '@mp/common/svg';
import React, {JSX, useState} from 'react';

const ACCORDION_ITEM: string = 'mp-accordion-item';

interface AccordionItemProps extends React.PropsWithChildren {
    header: JSX.Element | string;
}

export function AccordionItem(props: AccordionItemProps): JSX.Element {
    const {header, children} = props;
    const [opened, setOpened] = useState(false);

    return (
        <div className={ACCORDION_ITEM}>
            <div className={`${ACCORDION_ITEM}-header`} onClick={onHeaderClick}>
                <div>{header}</div>
                {opened ? <SvgButton icon={Icon.AngleUp} /> : <SvgButton icon={Icon.AngleDown} />}
            </div>
            {opened && <div className={`${ACCORDION_ITEM}-content`}>{children}</div>}
        </div>
    );

    function onHeaderClick(): void {
        setOpened(!opened);
    }
}
