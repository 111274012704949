import {ImageCrop} from '@mp/common/image-crop/ui/ImageCrop';
import {Router} from '@mp/route';
import React, {CSSProperties, JSX} from 'react';
import {useNavigate} from 'react-router-dom';
import {uploadImage} from '../services';

export function RecipeEditImagePage({recipeId, imgSrc}: {recipeId: number; imgSrc: string}): JSX.Element {
    const navigate = useNavigate();

    return (
        <div style={getPageStyles()}>
            <ImageCrop
                buttonName="Ustaw nowy obrazek"
                imgSrc={imgSrc}
                onGetCroppedImage={(img) => uploadImage(recipeId, img.base64).then(() => navigate(Router.getUrlToRecipesPage()))}
            />
        </div>
    );
}

function getPageStyles(): CSSProperties {
    return {maxWidth: '500px', padding: '8px', margin: '0 auto'};
}
