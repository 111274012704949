import {BigButton, Button} from '@mp/common/components/button/ui/Button';
import {DateInput} from '@mp/common/components/date-input/ui/DateInput';
import {TextInput} from '@mp/common/components/text-input/ui/TextInput';
import {isNotEmpty} from '@mp/common/utils/array';
import {getCurrentDate, getCurrentTime} from '@mp/common/utils/date';
import React, {createRef, JSX, RefObject, useEffect, useState} from 'react';
import {t} from '../t.i18n';
import {Expense, ExpenseCategory} from '../types';
import styles from './NumKeyboard.module.scss';

const MP_NUM_KEYBOARD = 'mp-num-keyboard';
const DOT_KEY: string = '.';
const RESET_KEY: string = 'CE';
const MAX_INPUT_LENGTH: number = 6;
const KEYS: Array<number | string> = [7, 8, 9, 4, 5, 6, 1, 2, 3, RESET_KEY, 0, DOT_KEY];
const DESCRIPTION_NEEDED_FOR_VALUE: number = 500;

interface NumKeyboardProps {
    expenseCategory: ExpenseCategory;
    onAddClick: (expense: Expense) => Promise<void>;
}

export function NumKeyboard(props: NumKeyboardProps): JSX.Element {
    const {onAddClick, expenseCategory} = props;
    const valueInput: RefObject<HTMLInputElement> = createRef<HTMLInputElement>();
    const dateInput: RefObject<HTMLInputElement> = createRef<HTMLInputElement>();
    const descriptionInput: RefObject<HTMLInputElement> = createRef<HTMLInputElement>();

    const [isPending, setIsPending] = useState<boolean>(false);
    const [value, setValue] = useState<number>(0);
    const [description, setDescription] = useState<string>('');

    const disabled: boolean = isPending || value === 0 || (value >= DESCRIPTION_NEEDED_FOR_VALUE && description.length < 2);

    useEffect(() => {
        document.addEventListener('keydown', keydownEventHandler);
        return () => {
            document.removeEventListener('keydown', keydownEventHandler);
        };
    }, []);

    function keydownEventHandler(event: KeyboardEvent): void {
        const {key} = event;

        if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(key)) {
            handleClick(key);
        } else if (key === ',') {
            handleClick(DOT_KEY);
        } else if (key === 'Delete') {
            handleClick(RESET_KEY);
        } else if (key === 'Enter') {
            onAddButtonClick();
        }
    }

    return (
        <div className={MP_NUM_KEYBOARD}>
            <div className={`${MP_NUM_KEYBOARD}-category`}>{expenseCategory.name}</div>
            <DateInput reference={dateInput} defaultValue={getCurrentDate()} />
            <div className={`${MP_NUM_KEYBOARD}-top`}>
                <TextInput reference={valueInput} disabled={true} />
            </div>
            <div className={`${MP_NUM_KEYBOARD}-numbers-container`}>{KEYS.map((key) => renderSquare(key))}</div>
            <div className={`${MP_NUM_KEYBOARD}-footer`}>
                <TextInput
                    placeholder={t.description}
                    reference={descriptionInput}
                    onChange={(event) => {
                        setDescription(event.target.value);
                    }}
                />
                <BigButton disabled={disabled} title={t.add} onClick={() => onAddButtonClick()} />
            </div>
            {isNotEmpty(expenseCategory.suggestions) && (
                <div className={styles.suggestions}>
                    {expenseCategory.suggestions.map((suggestion) => (
                        <Button
                            onClick={() => {
                                descriptionInput.current.value = suggestion;
                                setDescription(suggestion);
                            }}
                            key={suggestion}
                            title={suggestion}
                        />
                    ))}
                </div>
            )}
        </div>
    );

    function onAddButtonClick(): void {
        setIsPending(true);
        onAddClick({
            id: null,
            typeId: expenseCategory.id,
            value: Number(valueInput.current.value),
            date: `${dateInput.current.value} ${getCurrentTime()}`,
            description: description || null
        }).finally(() => setIsPending(false));
    }

    function renderSquare(key: number | string): JSX.Element {
        return <BigButton disabled={isPending} title={key} key={key} onClick={() => handleClick(key)} />;
    }

    function handleClick(key: number | string): void {
        const inputValue: string = valueInput.current.value;
        if (key === RESET_KEY) {
            if (inputValue.length) {
                valueInput.current.value = '';
            }
            return;
        }
        if (inputValue.length <= MAX_INPUT_LENGTH) {
            if (inputValue.includes(DOT_KEY)) {
                if (key === DOT_KEY) {
                    return;
                }
                if (inputValue.indexOf(DOT_KEY) === inputValue.length - 3) {
                    return;
                }
            }
            valueInput.current.value += key;
        }

        setValue(Number(valueInput.current.value));
    }
}
