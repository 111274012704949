import {Icon, SvgButton} from '@mp/common/svg';
import debounce from 'lodash.debounce';
import React, {useCallback, useContext, useRef, RefObject, JSX} from 'react';
import {addItemToShoppingListService, updateListItemCountService} from '../../shopping-list/services';
import {ShoppingListItem} from '../../shopping-list/types';
import {RecipesPageContext} from '../context/RecipesPage.context';
import {Ingredient} from '../types';

export function IngredientRow({ingredient, portions}: {ingredient: Ingredient; portions: number}): JSX.Element {
    const {shoppingList, shoppingItems, reloadData} = useContext(RecipesPageContext);
    const toAddCount: RefObject<number> = useRef(0);
    const toSubCount: RefObject<number> = useRef(0);

    const relatedShoppingItem: ShoppingListItem = shoppingList.find(({item_id}) => item_id === ingredient.shoppingItemId);

    const onCartShopClick = useCallback(() => {
        addItemToShoppingListService(ingredient.shoppingItemId, 1).then(reloadData);
    }, []);

    return (
        <div className="ingredient-row">
            <div className="ingredient-row-left">
                <div className="ingredient-name">{ingredient.name}</div>
                <div className="ingredient-unit-value">{`${Number(((ingredient.value ?? 0) * portions).toFixed(1))} ${
                    ingredient.unit
                }`}</div>
            </div>
            <div className="ingredient-row-right">{resolveShoppingListLabel()}</div>
        </div>
    );

    function resolveShoppingListLabel(): JSX.Element {
        if (relatedShoppingItem) {
            const {count, done} = relatedShoppingItem;
            if (!done) {
                return (
                    <>
                        <UpdateListItemButton icon={Icon.Minus} counter={toSubCount} multiplier={-1} />
                        <span>{count}</span>
                        <UpdateListItemButton icon={Icon.Plus} counter={toAddCount} multiplier={1} />
                    </>
                );
            }
            return <SvgButton icon={Icon.Check} color="black" />;
        }

        function UpdateListItemButton({
            icon,
            counter,
            multiplier
        }: {
            icon: Icon;
            counter: RefObject<number>;
            multiplier: -1 | 1;
        }): JSX.Element {
            return (
                <SvgButton
                    icon={icon}
                    color="black"
                    onClickCapture={() => (counter.current += 1)}
                    onClick={debounce(
                        () =>
                            updateListItemCountService({
                                id: relatedShoppingItem.id,
                                count: relatedShoppingItem.count + multiplier * counter.current
                            }).then(({success}) => {
                                if (success) {
                                    counter.current = 0;
                                    reloadData();
                                }
                            }),
                        500
                    )}
                />
            );
        }

        const isInDataBase: boolean = !!shoppingItems.find(({id}) => id === ingredient.shoppingItemId);

        return (
            <>
                {/*<SvgButton icon={Icon.HouseChimney} color="black" />*/}
                {isInDataBase && <SvgButton icon={Icon.CartShopping} color="black" onClick={onCartShopClick} />}
            </>
        );
    }
}
