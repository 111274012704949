interface Result<T> {
    fulfilled: T[];
    rejected: unknown[];
}

export async function promiseAllSettled<T>(promises: Promise<T>[]): Promise<Result<T>> {
    const responses: Result<T> = {
        fulfilled: [],
        rejected: []
    };
    await Promise.allSettled<T>(promises).then((results) => {
        results.forEach((result) => {
            if (result.status === 'fulfilled') {
                responses.fulfilled.push(result.value);
            } else {
                responses.rejected.push(result.reason);
            }
        });
    });

    return responses;
}
