import {Icon, SvgButton} from '@mp/common/svg';
import React, {JSX} from 'react';
import {Link} from 'react-router-dom';

const MP_TOOLS_BAR = 'mp-tools-bar';

export interface ToolbarCustomButton {
    icon: Icon;
    onCustomClick: () => void;
}

interface ToolsBarProps {
    customButton?: {
        icon: Icon;
        onCustomClick: () => void;
    };
    onAddClick?: () => void;
    onEditClick?: () => void;
    onDeleteClick?: () => void;
    /**
     * @deprecated The method should not be used
     */
    onBackClicked?: () => void;
    onBackCallback?: () => void;
    name?: string | JSX.Element;
    description?: string;
    right1?: JSX.Element;
}

export class ToolsBar extends React.Component<ToolsBarProps> {
    public render() {
        const {description, name, onBackClicked, onBackCallback, customButton, onAddClick, onEditClick, onDeleteClick} = this.props;
        return (
            <MP_TOOLS_BAR>
                <div className={MP_TOOLS_BAR}>
                    <div className={`${MP_TOOLS_BAR}-left`}>
                        {!!onBackCallback && <SvgButton icon={Icon.ArrowLeft} onClick={onBackCallback} />}
                        {!!onBackClicked && (
                            <Link to={{}} onClick={onBackClicked}>
                                <SvgButton icon={Icon.ArrowLeft} />
                            </Link>
                        )}
                        {!!name && <span className={`${MP_TOOLS_BAR}-left-name`}>{name}</span>}
                    </div>
                    <div className={`${MP_TOOLS_BAR}-right`}>
                        {this.props.right1}
                        {!!description && <span className={`${MP_TOOLS_BAR}-description`}>{description}</span>}
                        {!!onAddClick && <SvgButton icon={Icon.Plus} onClick={onAddClick} />}
                        {!!customButton && <SvgButton icon={customButton.icon} onClick={customButton.onCustomClick} />}
                        {!!onEditClick && <SvgButton icon={Icon.Pencil} onClick={onEditClick} />}
                        {!!onDeleteClick && <SvgButton icon={Icon.TrashCan} onClick={onDeleteClick} />}
                    </div>
                </div>
            </MP_TOOLS_BAR>
        );
    }
}
