export type Films = Array<Film>;
export type SearchMovieResult = Film;

export type FwId = number & {__brand: 'FwId'};

export interface VodProvider {
    id: number;
    name: string;
    path: string;
    order: number;
    promoted: boolean;
    displayName: string;
    link: string;
    abonaments: string;
    hasFilms: boolean;
    hasSerials: boolean;
    hasTvshows: boolean;
    originalProducer: boolean;
    countryCode: string;
}

export interface FilmVodProvider {
    vodProvider: number;
    id: number;
    film: number;
    start: string;
    link: string;
    externalId: string;
    status: number; // enum
    externalTitle: string;
    payments: VodPayment[];
}

export interface VodPayment {
    filmVod: number;
    accessType: number;
    currency: number; // enum
    price: number;
    buy: boolean;
    rent: boolean;
    subscription: boolean;
    free: boolean;
}

export interface FilmCriticsRating {
    rateValue: string;
    rateCount: string;
}

export enum InfoType {
    Character = 'character',
    Film = 'film',
    Person = 'person',
    Serial = 'serial'
}

export interface Film {
    fid: FwId;
    type: InfoType;
    matchedTitle: string;
    matchedLang: string;
    filmMainCast: Array<FilmMainCast>;
    info: FilmInfo;
    rating: FilmRating;
    criticsRating?: FilmCriticsRating;
}

interface FilmMainCast {
    id: number;
    name: string;
}

export interface FilmInfo {
    id: FwId;
    title: string;
    originalTitle: string;
    year: number;
    type: string;
    subType: string;
    posterPath: string;

    exist: boolean;
    toWatch: boolean;
    watched: boolean;
}

export interface FilmRating {
    id: number;
    count: number;
    rate: number;
    countWantToSee: number;
}
