import {CommonContext} from '@mp/common/context';
import {useWasInViewport} from '@mp/common/hooks/wasInViewPort';
import {Modal} from '@mp/common/modals/Modal';
import {Icon, SvgButton} from '@mp/common/svg';
import {getIntersection, isNotEmpty} from '@mp/common/utils/array';
import {getCurrentDateString} from '@mp/common/utils/date';
import {promiseAllSettled} from '@mp/common/utils/promise';
import {FW_FAVICON} from '@mp/filmweb/assets/fw-favicon';
import {loadFilmInfoService} from '@mp/filmweb/services/loadFilmInfo.service';
import {loadFilmRatingService} from '@mp/filmweb/services/loadFilmRating.service';
import {searchMoviesService} from '@mp/filmweb/services/searchMovies.service';
import {Film, FilmInfo, FilmRating, FwId} from '@mp/filmweb/types';
import {getFullImagePath} from '@mp/filmweb/utils/imagePath';
import {createLinkToMovie} from '@mp/filmweb/utils/link';
import {Router} from '@mp/route';
import classNames from 'classnames';
import React, {JSX, useContext, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {isAdmin, isAdminOrLoggedUser} from '../../../global';
import {updateMkDbMovieService, UpdateMkDvMovie} from '../services';
import {MovieInfo, Schedule} from '../types';
import {getShareLink} from '../utils/getMessengerShareLink';
import styles from './MovieInfoBox.module.scss';
import {RatingBox} from './RatingBox';
import {ScheduleTile} from './ScheduleTile';

interface FmMovieModalData {
    suggestedFwId: FwId;
    fwFilmsInfo: FilmInfo[];
}

export function MovieInfoBox(props: {
    isCacheUsed: boolean;
    movieInfo: MovieInfo;
    date: Date;
    onScheduleClick: (schedule: Schedule) => void;
}): JSX.Element {
    const {movieInfo, date, isCacheUsed, onScheduleClick} = props;
    const {context} = useContext(CommonContext);
    const [isLoadingFilmWebData, setIsLoadingFilmWebData] = useState<boolean>(false);
    const [updatedData, setUpdatedData] = useState<UpdateMkDvMovie>();
    const [selectFwMovieModalData, setSelectFwMovieModalData] = useState<FmMovieModalData>();

    const ref = useRef(null);
    const isInViewport = useWasInViewport(ref);

    const {mkId, title, filmParams, poster, filmUrl, schedules, promoLabels, dayTitle, cast} = movieInfo;

    const isLogged: boolean = isAdminOrLoggedUser();
    const isEditMode: boolean = isLogged && context.isEditMode;
    const fwId: FwId = updatedData?.fwId ?? movieInfo.fwId;
    const fwRate: number = updatedData?.fwRate ?? movieInfo.fwRate;
    const fwRateCount: number = updatedData?.fwRateCount ?? movieInfo.fwRateCount;
    const wantToWatchRate: number = updatedData?.wantToWatchRate ?? movieInfo.wantToWatchRate;
    const updatedAt: string = updatedData?.updated ?? movieInfo.updated;
    const isHiddenMovie: boolean = wantToWatchRate === 0;

    const setPartialUpdateData = (data: Partial<UpdateMkDvMovie>): void => {
        setUpdatedData((prev): UpdateMkDvMovie => ({...prev, ...data}));
    };

    const handleWantToWatchClick = (n: number): void => {
        if (mkId) {
            const toUpdate: UpdateMkDvMovie = {mkId, wantToWatchRate: n} as UpdateMkDvMovie;
            updateMkDbMovieService(toUpdate).then(({success}) => {
                if (success) {
                    setPartialUpdateData(toUpdate);
                }
            });
        }
    };

    const handleUpdateMkDbMovie = async (filmWebId: FwId) => {
        if (!isAdmin() || isCacheUsed) {
            return;
        }

        let fwRating: FilmRating = null;

        return loadFilmRatingService(filmWebId)
            .then((_rating) => {
                fwRating = _rating;
            })
            .finally(() => {
                const toUpdate: UpdateMkDvMovie = {fwId: filmWebId, mkId} as UpdateMkDvMovie;

                if (fwRating?.rate != null) {
                    toUpdate.fwRate = fwRating.rate;
                    toUpdate.fwRateCount = fwRating.count;
                    toUpdate.updated = getCurrentDateString();
                }

                updateMkDbMovieService(toUpdate).then(({success}) => {
                    if (success) {
                        setPartialUpdateData(toUpdate);
                        setIsLoadingFilmWebData(false);
                    }
                });
            });
    };

    const handleFwRateClick = (): void => {
        if (fwId) {
            handleUpdateMkDbMovie(fwId);
        }
    };

    if (isInViewport && fwId && fwRate != null && updatedAt !== getCurrentDateString()) {
        handleUpdateMkDbMovie(fwId).then();
    }

    return (
        <div id={mkId} ref={ref} className={classNames('movie-info', {[styles.gray]: isHiddenMovie})}>
            <div className="movie-info-top">
                <div className="movie-info-top-left">
                    {resolvePromoLabels()}
                    <img src={poster} alt={poster} onClick={() => onScheduleClick(null)} />
                </div>
                <div className="movie-info-top-right">
                    <div className="movie-info-top-right-title">
                        <div>
                            <a href={fwId ? createLinkToMovie(fwId) : filmUrl}>{title}</a>
                        </div>
                        <div className="movie-info-top-right-title-buttons">
                            {isLogged && (
                                <Link to={Router.getUrlToMoviePage('search', title)}>
                                    <SvgButton icon={Icon.Video} />
                                </Link>
                            )}
                            <a href={getShareLink({date: date, mkId})}>
                                <SvgButton icon={Icon.Share} />
                            </a>
                            {isAdmin() && !isHiddenMovie && fwRate == null && (
                                <img onClick={() => onLoadFilmWebDataClick()} src={FW_FAVICON} alt="fav" />
                            )}
                            {isAdmin() && isEditMode && mkId && (
                                <div className={styles.wtwc}>
                                    {[0, 1, 2, 3, 4, 5].map((n) => (
                                        <span
                                            className={classNames({[styles.wtwcs]: wantToWatchRate === n})}
                                            key={n}
                                            onClick={() => handleWantToWatchClick(n)}
                                        >
                                            [{n}]
                                        </span>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                    {isLoadingFilmWebData ? (
                        <span>Wczytywanie</span>
                    ) : (
                        <RatingBox
                            wantToWatch={wantToWatchRate >= 1}
                            filmRating={{fwRate, fwRateCount}}
                            onStarClick={updatedAt == getCurrentDateString() ? undefined : handleFwRateClick}
                        />
                    )}
                    <div className="movie-info-top-right-params" dangerouslySetInnerHTML={{__html: filmParams}} />
                </div>
            </div>

            <div className="movie-info-bottom">
                <div className="movie-info-bottom-day">{dayTitle}</div>
                <div className="movie-info-bottom-grid">
                    {schedules?.map((schedule: Schedule) => (
                        <ScheduleTile key={schedule.bookingLink} mkId={mkId} schedule={schedule} onClick={onScheduleClick} />
                    ))}
                </div>
            </div>
            {isNotEmpty(selectFwMovieModalData?.fwFilmsInfo) && (
                <Modal handleClose={() => setSelectFwMovieModalData(null)} title="Dopasuj po plakacie">
                    <div className={styles.fwModal}>
                        {selectFwMovieModalData.fwFilmsInfo.map((currentFwData) => (
                            <img
                                key={currentFwData.id}
                                onClick={() => handleUpdateMkDbMovie(currentFwData.id).then(() => setSelectFwMovieModalData(null))}
                                className={classNames({
                                    [styles.fwModalSelectedImg]: currentFwData.id === selectFwMovieModalData.suggestedFwId
                                })}
                                src={currentFwData.posterPath}
                                alt={currentFwData.posterPath}
                            />
                        ))}
                    </div>
                </Modal>
            )}
        </div>
    );

    function resolvePromoLabels(): JSX.Element {
        return (
            <div className="promo-label-container">
                {promoLabels?.map((label: string) => (
                    <span key={label} className={label.toLowerCase()}>
                        {label}
                    </span>
                ))}
            </div>
        );
    }

    function onLoadFilmWebDataClick(): void {
        if (fwId) {
            handleUpdateMkDbMovie(fwId);
            return;
        }

        setIsLoadingFilmWebData(true);
        searchMoviesService(title, 0).then((films) => {
            const possiblyMatchedFilm: Film = films[0];
            const fwCast: string[] = possiblyMatchedFilm.filmMainCast.map((cast) => cast.name);

            if (isNotEmpty(getIntersection(cast, fwCast))) {
                handleUpdateMkDbMovie(possiblyMatchedFilm.fid);
            } else {
                const fwIds: FwId[] = films.map(({fid}): FwId => fid);
                const suggestedFwId: FwId = films.find(({matchedTitle}) => matchedTitle.toLowerCase() === title.toLowerCase())?.fid;

                promiseAllSettled<FilmInfo>(fwIds.map(loadFilmInfoService)).then((result) => {
                    const fwFilmsInfo: FilmInfo[] = result.fulfilled.map(
                        (entry): FilmInfo => ({...entry, posterPath: getFullImagePath(entry.posterPath)})
                    );
                    setSelectFwMovieModalData({fwFilmsInfo, suggestedFwId});
                });
            }
        });
    }
}
