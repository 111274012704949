import {InputData, SelectOption} from '@mp/common/components/form-input/types';
import {toDate} from '@mp/common/utils/converters';
import {ChecklistItem} from '../types';

export function getFormsModalData(selectedListId: number, item?: ChecklistItem): Array<InputData<ChecklistItem>> {
    return [
        {id: 'id', displayName: 'Id', type: 'hidden', defaultValue: item?.id},
        {id: 'name', displayName: 'Nazwa', type: 'text', defaultValue: item?.name ?? '', validation: {minLength: 3}},
        {id: 'categoryId', displayName: 'Id listy', type: 'hidden', defaultValue: selectedListId},
        {id: 'deadline', displayName: 'Deadline', type: 'date', defaultValue: toDate(item?.deadline)},
        {
            id: 'maintainerUserId',
            displayName: 'Odpowiedzialny za wykonanie',
            type: 'select-number',
            selectOptions: getSelectOptions(),
            defaultValue: item?.maintainerUserId ?? null
        },
        {
            id: 'visibleForUserId',
            displayName: 'Widoczne tylko dla',
            type: 'select-number',
            selectOptions: getSelectOptions(),
            defaultValue: item?.visibleForUserId ?? null
        }
    ];
}

function getSelectOptions(): Array<SelectOption> {
    return [
        {name: '', value: null},
        {name: 'Klaudia', value: '2'},
        {name: 'Maciej', value: '1'}
    ];
}
