import {Button} from '@mp/common/components/button/ui/Button';
import {DateInput} from '@mp/common/components/date-input/ui/DateInput';
import {CommonContext} from '@mp/common/context';
import {useNumberQueryParams} from '@mp/common/hooks/useQueryParams';
import {Logger} from '@mp/common/logger';
import {FormsModal} from '@mp/common/modals/FormsModal';
import {toDate} from '@mp/common/utils/converters';
import {FILTER_ID_PARAM, RouteName, Router} from '@mp/route';
import React, {JSX, useContext, useMemo, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {GiftsPageContext} from '../context/GiftsPage.context';
import {addGiftService} from '../services/gifts.services';
import {addPersonService} from '../services/giftsPeople.services';
import {GiftDto, Person} from '../types';
import styles from './GiftsPeople.module.scss';

const PEOPLE_CONTAINER = 'people-container';

export function GiftsPeople(): JSX.Element {
    const {people, groups, reloadData} = useContext(GiftsPageContext);
    const {context} = useContext(CommonContext);
    const dateInput = useRef<HTMLInputElement>(null);
    const filterId: number = useNumberQueryParams(FILTER_ID_PARAM);
    const [addPerson, setAddPerson] = useState<boolean>();

    const getPeopleIdsFromCheckboxes = (): Array<HTMLInputElement> => {
        return Array.from(document.querySelectorAll(`.${PEOPLE_CONTAINER} input`)) as Array<HTMLInputElement>;
    };

    const handleSelectAllPeople = (): void => {
        getPeopleIdsFromCheckboxes().forEach((el) => {
            el.checked = true;
        });
    };

    const handleAddGiftsClick = () => {
        const peopleIds: Array<number> = getPeopleIdsFromCheckboxes()
            .filter(({checked}) => checked)
            .map(({value}) => Number(value));

        const date: Date = toDate(dateInput.current.value);
        if (date) {
            const gifts: Array<GiftDto> = peopleIds.map(
                (id): GiftDto => ({
                    id: null,
                    date: dateInput.current.value,
                    personId: id,
                    name: null,
                    description: null,
                    price: null,
                    status: null
                })
            );

            Promise.all(gifts.map(addGiftService))
                .then(() => {
                    reloadData();
                })
                .catch((e) => Logger.wholeError(e));
        }
    };

    const filteredPeople = useMemo((): Array<Person> => {
        if (!filterId) {
            return people;
        }
        const peopleIdsInGroup: Array<number> = groups.find(({id}) => id === filterId)?.peopleIds;
        if (peopleIdsInGroup) {
            return people.filter(({id}) => peopleIdsInGroup.includes(id));
        }
        return [];
    }, [filterId]);

    const getFilterClass = (groupId: number): string => {
        const classes: Array<string> = [styles.filterLink];
        if (filterId === groupId) {
            classes.push(styles.filterSelected);
        }
        return classes.join(' ');
    };

    return (
        <div>
            <div>
                <div className={styles.filters}>
                    <Link className={getFilterClass(null)} to={RouteName.GIFTS_PEOPLE}>
                        Wszyscy
                    </Link>
                    {groups.map((group) => (
                        <Link key={group.id} className={getFilterClass(group.id)} to={Router.getUrlToGiftsPeople({filterId: group.id})}>
                            {group.name}
                        </Link>
                    ))}
                </div>
            </div>
            <div className={PEOPLE_CONTAINER}>{filteredPeople.map(PersonComponent)}</div>
            <div>
                <DateInput reference={dateInput} />
                <Button title="Zaznacz wszystkich" onClick={handleSelectAllPeople} />
                {context.isEditMode && <Button title="Dodaj prezenty" onClick={handleAddGiftsClick} />}
            </div>
            <div>{context.isEditMode && <Button title="Dodaj osobę" onClick={() => setAddPerson(true)} />}</div>
            {addPerson && (
                <FormsModal<Person>
                    title="Dodaj osobę"
                    mode="add"
                    inputs={[
                        {id: 'firstName', displayName: 'Imię', type: 'text', defaultValue: '', validation: {minLength: 2}},
                        {id: 'lastName', displayName: 'Nazwisko', type: 'text', defaultValue: '', validation: {minLength: 2}},
                        {id: 'birthday', displayName: 'Data urodzenia', type: 'date', defaultValue: null}
                    ]}
                    handleClose={() => setAddPerson(false)}
                    onSendForm={async (person) => {
                        addPersonService(person).then(({success}) => {
                            if (success) {
                                reloadData();
                            }
                        });
                    }}
                />
            )}
        </div>
    );
}

function PersonComponent(person: Person): JSX.Element {
    const {id, firstName, lastName} = person;

    const getName = () => (lastName ? `${firstName} ${lastName}` : firstName);

    return (
        <div key={id} className={styles.person}>
            <label>
                <div>{getName()}</div>
                <input type="checkbox" value={id} />
            </label>
        </div>
    );
}
