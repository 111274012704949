import {Logger} from '@mp/common/logger';
import {loadFilmInfoService} from '@mp/filmweb/services/loadFilmInfo.service';
import {changeImageSize} from '@mp/filmweb/utils/filmImage';
import {updatePosterService} from '../../services/updatePoster.service';
import {Movie} from '../inCinemaTab/types';

export function onImageError(movie: Movie): void {
    const {id, fid} = movie;
    Logger.warn(`Could not load image for: ${id}`);

    loadFilmInfoService(fid).then(({posterPath}) => {
        const newPosterPath = changeImageSize(posterPath, 2);
        if (newPosterPath.endsWith('.2.jpg')) {
            updatePosterService({id, poster: newPosterPath}).then(({success}) => {
                if (success) {
                    Logger.info(`Successfully updated poster:"${newPosterPath}" for fid:"${fid}"`);
                }
            });
        } else {
            alert('Error while updating image');
        }
    });
}
