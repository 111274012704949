import React, {useEffect, useMemo, useState} from 'react';

export function useWasInViewport(ref: React.RefObject<Element>): boolean {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const observer = useMemo(() => new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting)), []);

    useEffect(() => {
        if (ref?.current) {
            observer.observe(ref.current);
        }
        return () => {
            observer.disconnect();
        };
    }, [ref, observer]);

    if (isIntersecting) {
        observer.disconnect();
    }

    return isIntersecting;
}
