import './_fontsPage.scss';
import {Icon, SvgIcon} from '@mp/common/svg';
import React, {JSX} from 'react';

interface IFontItem {
    icon: Icon;
    value: string;
}

export function FontsPage(): JSX.Element {
    const fontItems: Array<IFontItem> = Object.entries(Icon).map((e) => ({value: e[1], icon: e[1]}));

    return (
        <div className="mp-fonts-page">
            {fontItems.map((item) => (
                <FontItem key={item.value} icon={item.icon} value={item.value} />
            ))}
        </div>
    );
}

function FontItem(props: IFontItem): JSX.Element {
    const {icon, value} = props;

    return (
        <div className="font-item">
            <div>{value}</div>
            <SvgIcon icon={icon} />
        </div>
    );
}
