import classNames from 'classnames';
import React, {JSX, useState} from 'react';
import {MP_MULTI_SELECT, SELECTED} from '../consts';
import {MultiOption} from '../types';

const MULTI_SELECT_OPTION: string = `${MP_MULTI_SELECT}-option`;

interface MultiOptionComponentProps {
    option: MultiOption;
    isSelected: boolean;
    onClick: () => void;
}

export function MultiOptionComponent(props: MultiOptionComponentProps): JSX.Element {
    const {option, isSelected} = props;
    const [isOptionSelected, setIsOptionSelected] = useState(isSelected);

    const className: string = classNames(MULTI_SELECT_OPTION, {[SELECTED]: isOptionSelected});

    return (
        <div
            className={className}
            onClick={() => {
                props.onClick();
                setIsOptionSelected(!isOptionSelected);
            }}
        >
            {option.name}
        </div>
    );
}
