import './contextMenuModal.scss';
import React, {createContext, JSX, PropsWithChildren, useContext} from 'react';
import {GrayedOutBackground} from '../../../components/background/ui/GrayedOutBackground';

const MODAL: string = 'mp-context-menu-modal';

interface ContextMenuModalProps extends PropsWithChildren {
    title?: string;
    onEdit?: () => void;
    onDelete?: () => void;
    onClose: () => void;
}

const ContextMenuContext = createContext({onClose: null});

export function ContextMenuModal(props: ContextMenuModalProps): JSX.Element {
    const {title, children, onEdit, onDelete, onClose} = props;

    return (
        <>
            <GrayedOutBackground onClick={() => onClose()} />
            <div className={MODAL} onContextMenu={(e) => e.preventDefault()}>
                {title && <div className="mp-context-menu-modal-title">{title}</div>}
                <ContextMenuContext.Provider value={{onClose}}>
                    {onEdit && <Option label="Edytuj" onClick={onEdit} />}
                    {onDelete && <Option label="Usuń" onClick={onDelete} />}
                    {children}
                </ContextMenuContext.Provider>
            </div>
        </>
    );
}
function Option(props: {label; onClick: () => void}): JSX.Element {
    const {onClose} = useContext(ContextMenuContext);
    return (
        <div
            className="mp-context-menu-modal-option"
            onClick={() => {
                props.onClick();
                onClose();
            }}
        >
            {props.label}
        </div>
    );
}

ContextMenuModal.Option = Option;
