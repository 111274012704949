import {Image} from '@mp/common/components/image/ui/Image';
import {Icon, SvgButton} from '@mp/common/svg';
import {getFullImagePath} from '@mp/filmweb/utils/imagePath';
import {createLinkToMovie} from '@mp/filmweb/utils/link';
import React, {JSX} from 'react';
import {Movie} from '../inCinemaTab/types';
import {onImageError} from './onImageError';

interface MovieRowParams {
    movie: Movie;
    topElement?: JSX.Element;
    onUpdate?: (id: number, inCinema: boolean) => void;
    onTitleClick?: (movie: Movie) => void;
}

export function MovieRow(params: MovieRowParams): JSX.Element {
    const {movie, onUpdate, onTitleClick, topElement} = params;
    const {id, fid, titleEn, titlePl, year, poster, watchedDate} = movie;

    return (
        <div key={id} className="movie-row" data-custom-fid={fid}>
            <div className="movie-row-poster">
                <Image src={getFullImagePath(poster)} alt="poster" onError={() => onImageError(movie)} />
            </div>
            <div className="movie-row-title">
                <div className="movie-row-title-top">{renderTopTitle()}</div>
                <div className="movie-row-title-bottom">{renderTitle()}</div>
            </div>
            {onUpdate && (
                <div className="movie-row-right">
                    <SvgButton icon={Icon.HouseChimney} onClick={() => onUpdate(movie.id, false)} />
                    <SvgButton icon={Icon.Video} onClick={() => onUpdate(movie.id, true)} />
                </div>
            )}
        </div>
    );

    function renderTopTitle(): JSX.Element {
        if (topElement) {
            return topElement;
        }
        if (watchedDate) {
            return <span>{watchedDate}</span>;
        }
        return null;
    }

    function renderTitle(): JSX.Element {
        const className: string = 'movie-row-title-bottom-center';
        const filmWebPageLink: string = createLinkToMovie(fid);

        if (onTitleClick) {
            return (
                <div className={className}>
                    <span onClick={() => onTitleClick(movie)}>{`${titlePl} (${year})`}</span>
                    <span onClick={() => onTitleClick(movie)}>{titleEn}</span>
                </div>
            );
        }

        return (
            <div className={className}>
                <a href={filmWebPageLink}>{`${titlePl} (${year})`}</a>
                <a href={filmWebPageLink}>{titleEn}</a>
            </div>
        );
    }
}
