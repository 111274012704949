import './_travelPaymentsPage.scss';
import {Color} from '@mp/common/colors';
import {SimpleToolBar} from '@mp/common/components/tool-bar';
import {useNumberQueryParams} from '@mp/common/hooks/useQueryParams';
import {Logger} from '@mp/common/logger';
import {FormsModal} from '@mp/common/modals/FormsModal';
import {toCurrency} from '@mp/common/utils/number';
import {ID_PARAM, Router} from '@mp/route';
import React, {JSX} from 'react';
import {Loading} from '../../components/loading';
import {isAdmin as isAdminCheck} from '../../global';
import {
    addTravelsService,
    editTravelsService,
    loadTravelDetailsService,
    loadTravelsService,
    LoadTravelsServiceResult,
    TravelData
} from './services';
import {TravelPayment, TravelPaymentsMap, ViewMode} from './types';
import {PaymentItem} from './ui/PaymentItem';
import {TravelList} from './ui/TravelList';
import {createFormInputs} from './utils/createFormInputs';

interface State {
    isLoaded: boolean;
    viewMode: ViewMode;
    selectedItem: TravelPayment;
    selectedTravel: TravelPaymentsMap;

    travels: Array<TravelData>;
}

interface TravelPaymentsPageProps {
    selectedTravelId: number;
}

export function TravelPaymentsPage(): JSX.Element {
    return <TravelPaymentsPageCC selectedTravelId={useNumberQueryParams(ID_PARAM)} />;
}

class TravelPaymentsPageCC extends React.Component<TravelPaymentsPageProps, State> {
    constructor(props: TravelPaymentsPageProps) {
        super(props);
        this.showAddItemWindow = this.showAddItemWindow.bind(this);
        this.showEditItemWindow = this.showEditItemWindow.bind(this);
        this.onTravelListItemClick = this.onTravelListItemClick.bind(this);

        this.state = {
            isLoaded: false,
            selectedItem: null,
            viewMode: ViewMode.List,
            selectedTravel: null,

            travels: []
        };
    }
    public componentDidMount() {
        this.loadData();
    }

    private loadData(): void {
        const {selectedTravelId} = this.props;
        let viewMode: ViewMode = ViewMode.List;
        if (selectedTravelId) {
            viewMode = ViewMode.Normal;
            this.loadTravelDetailsService(selectedTravelId);
        }

        loadTravelsService().then((result: LoadTravelsServiceResult) => this.setState({travels: result.travels, isLoaded: true, viewMode}));
    }

    public render() {
        return <div className="mp-travel-page">{this.renderPage()}</div>;
    }

    private renderPage() {
        const {isLoaded, viewMode, selectedItem, selectedTravel} = this.state;
        const {selectedTravelId} = this.props;
        const isAdmin: boolean = isAdminCheck();
        if (!isLoaded) {
            return <Loading />;
        }

        if (selectedTravelId == null) {
            return <TravelList travels={this.state.travels} onTravelListItemClick={(travelId) => this.onTravelListItemClick(travelId)} />;
        }

        if (selectedTravel == null) {
            return <Loading />;
        }

        return (
            <>
                <SimpleToolBar
                    name={this.renderTravelName()}
                    backLink={Router.getUrlToTravelPaymentPage()}
                    backgroundColor={Color.PinkDark}
                    onAddClick={() => this.showAddItemWindow(null)}
                />
                {Array.from(selectedTravel.values()).map((payments) => (
                    <PaymentItem
                        key={payments[0].name}
                        payments={payments}
                        onEditItem={this.showEditItemWindow}
                        onAddItem={this.showAddItemWindow}
                    />
                ))}

                {selectedItem && viewMode === ViewMode.Edit && (
                    <FormsModal
                        title={selectedItem.description}
                        inputs={createFormInputs(selectedItem, viewMode, isAdmin, selectedTravelId)}
                        mode="update"
                        handleClose={() => this.setState({viewMode: ViewMode.Normal, selectedItem: null})}
                        onSendForm={(object: object) => editTravelsService(object).then(({success}) => success && this.loadData())}
                    />
                )}
                {viewMode === ViewMode.Add && (
                    <FormsModal
                        title={'Nowy wydatek'}
                        inputs={createFormInputs(selectedItem, viewMode, isAdmin, selectedTravelId)}
                        mode="add"
                        handleClose={() => this.setState({viewMode: ViewMode.Normal, selectedItem: null})}
                        onSendForm={(object: object) => addTravelsService(object).then(({success}) => success && this.loadData())}
                    />
                )}
            </>
        );
    }

    private renderTravelName(): JSX.Element {
        const {sumTotal, sumK, sumM} = this.getSums();
        return (
            <span onClick={() => alert(`K: ${toCurrency(sumK)}\nM: ${toCurrency(sumM)}`)}>
                {this.getSelectedTravelName() + ` (${toCurrency(sumTotal, {withPenny: false})})`}
            </span>
        );
    }

    private getSums(): {sumTotal: number; sumK: number; sumM: number} {
        let sumTotal: number = 0;
        let sumK: number = 0;
        let sumM: number = 0;
        Array.from(this.state.selectedTravel.values()).forEach((x) => {
            sumTotal += x.map((s) => s.valueSum).reduce((a, b) => a + b);
            sumK += x.map((s) => s.valueK).reduce((a, b) => a + b);
            sumM += x.map((s) => s.valueM).reduce((a, b) => a + b);
        });
        return {sumTotal, sumK, sumM};
    }

    private getSelectedTravelName(): string {
        return this.state.travels.find(({id}) => id === this.props.selectedTravelId)?.name;
    }

    private onTravelListItemClick(id: number): void {
        this.setState({isLoaded: false});
        this.loadTravelDetailsService(id);
        this.setState({viewMode: ViewMode.Normal});
    }

    private loadTravelDetailsService(id: number): void {
        loadTravelDetailsService(id)
            .then((response: TravelPaymentsMap) => {
                this.setState({
                    selectedTravel: response,
                    isLoaded: true,
                    selectedItem: null
                });
            })
            .catch((e) => {
                this.setState({isLoaded: true});
                Logger.wholeError(e);
            });
    }

    private showAddItemWindow(name: string) {
        this.setState({viewMode: ViewMode.Add, selectedItem: {name} as TravelPayment});
    }

    private showEditItemWindow(item: TravelPayment) {
        this.setState({viewMode: ViewMode.Edit, selectedItem: item});
    }
}
