import Axios, {AxiosError, AxiosResponse} from 'axios';
import {insertServiceError} from '../components/error/ServiceError';
import {Logger} from '../logger';
import {toBoolean} from '../utils/converters';

const BASE_PATH: string = '/api';

type ServicePrefix = 'comp' | 'pages' | 'filmweb';
type Method = 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE';

interface ServiceGetParams {
    data?: object;
    prefix?: ServicePrefix;
    isSilentError?: boolean;
}

interface ServiceParams {
    data: object;
    prefix?: ServicePrefix;
}

interface Service {
    get: <T>(serviceName: string, options?: ServiceGetParams) => Promise<AxiosResponse<T>>;
    post: (serviceName: string, options: ServiceParams) => Promise<PostServiceResponse>;
    put: (serviceName: string, params: ServiceParams) => Promise<PutServiceResponse>;
    patch: (serviceName: string, params: ServiceParams) => Promise<PatchServiceResponse>;
    delete: (serviceName: string, params: ServiceParams) => Promise<DeleteServiceResponse>;
}
export const service: Service = {
    get: (serviceName, options = {}) =>
        loadServiceFactory({serviceName, data: options.data, prefix: options.prefix, isSilentError: options.isSilentError}),
    post: (serviceName, {data, prefix}) => insertServiceFactory({serviceName, data, prefix}),
    put: (serviceName, {data, prefix}) => updateServiceFactory({serviceName, data, prefix, method: 'PUT'}),
    patch: (serviceName, {data, prefix}) => updateServiceFactory({serviceName, data, prefix, method: 'PATCH'}),
    delete: (serviceName, {data, prefix}) => deleteServiceFactory({serviceName, data, prefix})
};

function resolveParams(data: object): string {
    if (data == null) {
        return null;
    }
    const entries: Array<[string, unknown]> = Object.entries(data);
    if (entries.length === 0) {
        return null;
    }

    const paramsArray: Array<string> = [];
    entries.forEach((entry) => {
        const key: string = entry[0];
        const value: unknown = entry[1];
        if (key != null && key !== '' && value != null) {
            paramsArray.push(`${key}=${value}`);
        }
    });
    return `?${paramsArray.join('&')}`;
}

interface ServiceFactoryParams {
    serviceName: string;
    prefix?: ServicePrefix;
    data?: unknown;
    method?: Method;
}

export interface ServiceResponseWithError<T> {
    error?: AxiosError;
    data: T;
}

export interface PostServiceResponse {
    success: boolean;
    id: number;
}

export type PatchServiceResponse = ServiceResponseWithAffectedRow;
export type PutServiceResponse = ServiceResponseWithAffectedRow;

interface ServiceResponseWithAffectedRow {
    success: boolean;
    message?: string;
    rowCount: number;
}

export interface DeleteServiceResponse {
    success: boolean;
    deletedItems: number;
}

function loadServiceFactory<T>(
    param: Pick<ServiceGetParams, 'isSilentError'> & Pick<ServiceFactoryParams, 'serviceName' | 'data' | 'prefix'>
): Promise<AxiosResponse<T>> {
    const {serviceName, data, prefix = 'pages', isSilentError} = param;
    const params: string = resolveParams(data as object);
    const serviceFullName: string = createServiceFullName(serviceName, prefix);
    return resolveAxiosMethod<AxiosResponse<T>>('GET', serviceFullName, undefined, params)
        .then((result: AxiosResponse<T>) => validateServiceName(result, serviceName, prefix))
        .catch((error: AxiosError) => {
            if (!isSilentError) {
                insertServiceError(error);
            }
            return {data: [], status: error.status, isError: true} as unknown as AxiosResponse;
        });
}

function insertServiceFactory(param: Omit<ServiceFactoryParams, 'method'>): Promise<PostServiceResponse> {
    const {serviceName, data, prefix = 'pages'} = param;
    return Axios.post(createServiceFullName(serviceName, prefix), data)
        .then((result: AxiosResponse<PostServiceResponse>) => validateServiceName(result, serviceName, prefix))
        .then((result: AxiosResponse<PostServiceResponse>) => {
            const id: number = Number(result.data.id);
            const success: boolean = toBoolean(result.data.success);
            const response: PostServiceResponse = {id, success};
            if (!success) {
                Logger.wholeError(response);
                throw Error('Error: EC_100');
            }
            return response;
        })
        .catch((error) => {
            insertServiceError(error);
            return {id: null, success: false} as PostServiceResponse;
        });
}

function updateServiceFactory(param: ServiceFactoryParams): Promise<ServiceResponseWithAffectedRow> {
    const {serviceName, data, prefix = 'pages', method} = param;
    const serviceFullName: string = createServiceFullName(serviceName, prefix);

    return resolveAxiosMethod<AxiosResponse<PutServiceResponse>>(method, serviceFullName, data)
        .then((result: AxiosResponse<PutServiceResponse>) => validateServiceName(result, serviceName, prefix))
        .then((result: AxiosResponse<PutServiceResponse>) => {
            const rowCount: number = Number(result.data.rowCount) || 0;
            const success: boolean = toBoolean(result.data.success);
            const message: string = result.data.message;
            const response: ServiceResponseWithAffectedRow = {success, message, rowCount};
            if (!success) {
                if (response.message) {
                    throw Error(response.message);
                }
                Logger.wholeError(response);
                throw Error('Error: EC_200');
            }
            return response;
        })
        .catch((error) => {
            insertServiceError(error);
            return {rowCount: 0, success: false} as ServiceResponseWithAffectedRow;
        });
}

function resolveAxiosMethod<T>(method: Method, serviceName: string, data: unknown, params?: string): Promise<T> {
    switch (method) {
        case 'POST':
            return Axios.post(serviceName, data ?? params);
        case 'GET':
            return Axios.get(`${serviceName}${params ? params : ''}`);
        case 'PUT':
            return Axios.put(serviceName, data);
        case 'PATCH':
            return Axios.patch(serviceName, data);
        case 'DELETE':
            return Axios.delete(serviceName, {data});
        default:
            return null;
    }
}

function deleteServiceFactory(param: Omit<ServiceFactoryParams, 'method' | 'params'>): Promise<DeleteServiceResponse> {
    const {serviceName, data, prefix = 'pages'} = param;
    const serviceFullName: string = createServiceFullName(serviceName, prefix);

    return resolveAxiosMethod<AxiosResponse<DeleteServiceResponse>>('DELETE', serviceFullName, data)
        .then((result: AxiosResponse<DeleteServiceResponse>) => validateServiceName(result, serviceName, prefix))
        .then((result: AxiosResponse<DeleteServiceResponse>) => {
            const response: DeleteServiceResponse = result.data;
            if (!response.success) {
                Logger.wholeError(response);
                throw Error('Error: EC_300');
            }
            return response;
        })
        .catch((error) => {
            insertServiceError(error);
            return {deletedItems: 0, success: false} as DeleteServiceResponse;
        });
}

export function createServiceFullName(serviceName: string, prefix: ServicePrefix): string {
    return `${BASE_PATH}/${prefix}.${serviceName}`;
}

function validateServiceName<T>(result: AxiosResponse<T>, serviceName: string, prefix: ServicePrefix): AxiosResponse<T> {
    if (typeof result.data === 'string' && result.data.startsWith('<!doctype')) {
        throw new Error(`Service "${createServiceFullName(serviceName, prefix)}" not found`);
    }
    return result;
}
