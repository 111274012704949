import './grayedOutBackground.scss';
import React, {JSX, useEffect} from 'react';

export function GrayedOutBackground(props: {onClick: () => void}): JSX.Element {
    const {onClick} = props;

    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = 'auto';
        };
    });

    return <div className="mp-grayed-out-background" onClick={onClick} onContextMenu={(e) => e.preventDefault()} />;
}
