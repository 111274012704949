import {toDateString} from '@mp/common/utils/converters';
import {addDay, isCurrentDay, isTheSameDay, resolveDayName} from '@mp/common/utils/date';

export const resolveDayLabel = (date: Date): string => {
    const currentDate = new Date();

    const resolvePrefix = (): string => {
        if (isCurrentDay(date)) {
            return 'Dzisiaj';
        }
        if (isTheSameDay(date, addDay(currentDate, 1))) {
            return 'Jutro';
        }
        if (isTheSameDay(date, addDay(currentDate, 2))) {
            return 'Pojutrze';
        }
        return resolveDayName(date);
    };

    return `${resolvePrefix()}, ${toDateString(date, 'dd month')}`.toLowerCase();
};
