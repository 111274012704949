import {ConfirmModal} from '@mp/common/modals/ConfirmModal';
import {Icon, SvgButton} from '@mp/common/svg';
import {toDateString} from '@mp/common/utils/converters';
import classNames from 'classnames';
import React, {JSX, useState} from 'react';
import {ToolsBar} from '../../tools-bar';
import {removeDateService, setDoneDateService} from '../services';
import {CalendarDate, GridCell} from '../types';
import {resolveFullEventName} from '../utils/resolveFullEventName';

const CPD: string = 'calendar-preview-dates';

interface PreviewDatesViewProps {
    onBackCallback: () => void;
    onAddCallback: () => void;
    onEditCallback: (calendarDate: CalendarDate) => void;
    selectedCell: GridCell;
    displayedDate: Date;
}

export function PreviewDatesView(props: PreviewDatesViewProps): JSX.Element {
    const {onBackCallback, onAddCallback, onEditCallback, selectedCell, displayedDate} = props;
    const [itemToRemove, setItemToRemove] = useState<CalendarDate>(null);

    return (
        <div className={CPD}>
            <ToolsBar onBackCallback={onBackCallback} onAddClick={onAddCallback} />
            <div className={`${CPD}-date`}>{toDateString(selectedCell.date)}</div>
            <div className={`${CPD}-rows`}>
                {selectedCell.dates.map((cellDate) => {
                    const name: string = resolveFullEventName(cellDate, {showEventName: true, showRepeatName: true, displayedDate});
                    return (
                        <div key={cellDate.id} className={classNames('preview-row', {'other-user-date': !cellDate.isOwn})}>
                            <div>
                                <span>{name}</span>
                            </div>
                            <div className="preview-row-icons">
                                <SvgButton icon={Icon.PenToSquare} onClick={() => onEditCallback(cellDate)} style={{marginRight: '8px'}} />
                                {cellDate.isRemovable && <SvgButton icon={Icon.TrashCan} onClick={() => setItemToRemove(cellDate)} />}
                                {!cellDate.isRemovable && (
                                    <SvgButton icon={Icon.Check} onClick={() => markAsReady(cellDate, selectedCell.date)} />
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
            {itemToRemove && (
                <ConfirmModal
                    title="Usuwanie"
                    question={`Czy na pewno chcesz usunąć ${itemToRemove.name}?`}
                    handleConfirm={() => removeDate(itemToRemove.id)}
                    handleClose={() => setItemToRemove(null)}
                />
            )}
        </div>
    );
}

function removeDate(id: number): void {
    removeDateService(id).then((res) => res.success && window.location.reload());
}

function markAsReady(calendarDate: CalendarDate, realDate: Date): void {
    setDoneDateService({id: calendarDate.id, doneDate: toDateString(realDate)}).then((res) => res.success && window.location.reload());
}
