import {DeleteServiceResponse, service, PostServiceResponse} from '@mp/common/api';
import {toDateString} from '@mp/common/utils/converters';
import {AxiosResponse} from 'axios';
import {DayOff, WorkDayType} from '../types';
import {resolveWorkTypeValue} from '../utils/workDayType';

const SERVICE_NAME: string = 'workHours.daysOff';

interface ResponseDto {
    availableYears: Array<number>;
    allowedDays: number;
    year: number;
    daysOff: Array<DayOffDTO>;
}
interface Response {
    allowedDays: number;
    availableYears: Array<number>;
    year: number;
    usedDaysOff: number;
    daysOff: Array<DayOff>;
}

interface DayOffDTO {
    id: number;
    type: WorkDayType;
    timeIn: string;
}

export function loadDaysOffService(employerId: number, year: number): Promise<Response> {
    const calculateTotalValue = (dayOffs: Array<DayOffDTO>): number =>
        dayOffs.reduce((total, dayOff) => total + resolveWorkTypeValue(dayOff.type), 0);

    return service.get<ResponseDto>(SERVICE_NAME, {data: {employerId, year}}).then(
        (result: AxiosResponse<ResponseDto>): Response => ({
            allowedDays: result.data.allowedDays,
            year: result.data.year,
            availableYears: result.data.availableYears,
            usedDaysOff: calculateTotalValue(result.data.daysOff),
            daysOff: result.data.daysOff.map(
                (x): DayOff => ({
                    id: x.id,
                    date: new Date(x.timeIn),
                    dateString: toDateString(new Date(x.timeIn)),
                    type: x.type
                })
            )
        })
    );
}

export function addDayOffService(date: string, type: WorkDayType, employerId: number): Promise<PostServiceResponse> {
    return service.post(SERVICE_NAME, {data: {date, type, employerId}});
}

export function deleteDayOffService(id: number): Promise<DeleteServiceResponse> {
    return service.delete(SERVICE_NAME, {data: {id}});
}
