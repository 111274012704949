import {service} from '@mp/common/api';
import {FilmVodProvider, FwId} from '../types';

export type FilmVodProvidersResult = FilmVodProvider[];

const CACHE: Map<FwId, FilmVodProvidersResult> = new Map();

export async function loadFilmVodProvidersService(id: FwId): Promise<FilmVodProvidersResult> {
    const cachedData = CACHE.get(id);
    if (cachedData) {
        return cachedData;
    }

    return service
        .get<FilmVodProvider[]>('loadVodProviders', {data: {id}, prefix: 'filmweb'})
        .then((result) => {
            return result.data;
        })
        .then((data) => {
            if (Array.isArray(data)) {
                CACHE.set(id, data);
            }
            return data;
        });
}
