import './_invoicesPage.scss';
import {SimpleToolBar} from '@mp/common/components/tool-bar';
import {FormsModal} from '@mp/common/modals/FormsModal';
import {toDateString} from '@mp/common/utils/converters';
import {millisecondsToDays} from '@mp/common/utils/date';
import React, {JSX, useEffect, useState} from 'react';
import {Loading} from '../../../components/loading';
import {addInvoiceService, loadInvoicesService} from '../services/invoices.service';
import {Invoice} from '../types';
import {getFromData} from '../utils/getFromData';

const MP_INVOICES_PAGE: string = 'mp-invoices-page';

export function InvoicesPage(): JSX.Element {
    const [invoices, setInvoices] = useState<Array<Invoice>>();
    const [isAddModalVisible, setIsAddModalVisible] = useState<boolean>(false);

    useEffect(() => {
        loadInvoicesService().then((result) => setInvoices(result));
    }, []);

    if (invoices == null) {
        return <Loading />;
    }

    return (
        <div className={MP_INVOICES_PAGE}>
            <SimpleToolBar name="Faktury" onAddClick={() => setIsAddModalVisible(true)} />
            <div className="invoices">{invoices.map((invoice) => renderInvoiceRow(invoice))}</div>
            {isAddModalVisible && (
                <FormsModal<Invoice>
                    title="Dodaj fakturę"
                    mode="add"
                    inputs={getFromData()}
                    handleClose={() => setIsAddModalVisible(false)}
                    onSendForm={(data) => addInvoiceService(data).then(({success}) => success && window.location.reload())}
                />
            )}
        </div>
    );

    function renderInvoiceRow(invoice: Invoice): JSX.Element {
        const {id, shopName, dateFrom, dateTo, value, details} = invoice;
        const currencyValue: string = `${value.toFixed(2)} zł`;
        const daysLeftOfGuaranty: string = millisecondsToDays(dateTo.getTime() - new Date().getTime()).toFixed(0) + ' dni';

        return (
            <div key={id} className="invoice">
                <div className="invoice-header">
                    <span>{shopName}</span>
                    <span>{currencyValue}</span>
                </div>
                <div className="invoice-details-1">
                    <div>
                        <span>
                            Zakupiono: <span>{toDateString(dateFrom)}</span>
                        </span>
                    </div>
                    <div>
                        <span>
                            Pozostało gwarancji: <span>{daysLeftOfGuaranty}</span>
                        </span>
                    </div>
                </div>
                <div className="invoice-details-2">
                    <span>
                        Szczegóły: <span>{details}</span>
                    </span>
                </div>
            </div>
        );
    }
}
