import './_footer.scss';
import React, {JSX} from 'react';
import {isAdminOrLoggedUser} from '../../global';

export function Footer(): JSX.Element {
    if (isAdminOrLoggedUser()) {
        return <div className="mp-footer" />;
    }
    return null;
}
