import {resolveDayShortName} from '@mp/common/utils/date';
import {toCurrency} from '@mp/common/utils/number';
import classNames from 'classnames';
import React, {CSSProperties, JSX} from 'react';
import {t} from '../i18n';
import {WorkDayData, WorkMonthData} from '../services';
import {ElapsedTime} from '../utils/elapsedTime';
import {resolveWorkTypeColor} from '../utils/workDayType';

export const SIDE_ROWS_LIMIT: number = 4;

interface WorkHoursTableProps {
    showDetails: boolean;
    monthWorkData: WorkMonthData;
    showAllRows: boolean;
    selectedRow: WorkDayData;
    onRowClick: (data: WorkDayData) => void;
    onExpandClick: () => void;
}

export function WorkHoursTable(params: WorkHoursTableProps): JSX.Element {
    const {showDetails, showAllRows, selectedRow, monthWorkData, onRowClick, onExpandClick} = params;

    const showHourlyRateInTable = (): boolean => {
        return monthWorkData.earningsPerDay > 0;
    };

    return (
        <table>
            <thead>
                <tr>
                    <th />
                    <th>{t.day}</th>
                    <th>{t.start}</th>
                    <th>{t.end}</th>
                    <th>{t.time}</th>
                    {showDetails && showHourlyRateInTable() && <th>{t.hourlyRate}</th>}
                    {showDetails && <th>{t.cash}</th>}
                </tr>
            </thead>
            <tbody>{renderTableRows()}</tbody>
        </table>
    );

    function renderTableRows(): Array<JSX.Element> {
        let dayWorkTime: number = 0;
        const {workDays = []} = monthWorkData ?? {};
        const shouldHide: boolean = !showAllRows;

        let earnedMoney: number = 0;

        return workDays.map((d: WorkDayData, index, array) => {
            let workTime: string;
            dayWorkTime += d.workTimeMilliseconds;
            earnedMoney += d.earned;

            if (index < array.length - 1 && array[index + 1].date === d.date) {
                workTime = '';
            } else {
                workTime = new ElapsedTime(dayWorkTime).getElapsedTimeString();
                dayWorkTime = 0;
            }

            if (shouldHide && index === SIDE_ROWS_LIMIT) {
                return (
                    <tr key="expand-row" style={{color: 'gray'}} onClick={onExpandClick}>
                        <td colSpan={10}>{t.more}</td>
                    </tr>
                );
            }

            const resolveStyle = (): CSSProperties => {
                const styles: CSSProperties = {color: resolveWorkTypeColor(d.type)};
                if (shouldHide && index >= SIDE_ROWS_LIMIT && index < workDays.length - SIDE_ROWS_LIMIT) {
                    styles.display = 'none';
                }
                return styles;
            };

            return (
                <tr
                    className={classNames({'is-last-entry': d.last, 'is-selected': selectedRow?.id === d.id})}
                    style={resolveStyle()}
                    onClick={() => onRowClick(d)}
                    key={index}
                >
                    <td className="col-day-short-name">{resolveDayPrefix(d)}</td>
                    <td>{d.date}</td>
                    <td>{d.time_in}</td>
                    <td>{d.time_out}</td>
                    <td>{workTime}</td>
                    {showDetails && showHourlyRateInTable() && <td>{d.hourlyRate ?? 'null'}</td>}
                    {showDetails && <td>{toCurrency(earnedMoney)}</td>}
                </tr>
            );
        });
    }
}

function resolveDayPrefix(data: WorkDayData): string {
    const {fullDateTimeIn, fullDateTimeOut} = data;

    if (fullDateTimeIn && fullDateTimeOut) {
        if (fullDateTimeIn.getDate() !== fullDateTimeOut.getDate()) {
            return resolveDayShortName(fullDateTimeIn) + '/' + resolveDayShortName(fullDateTimeOut);
        }
    }
    return resolveDayShortName(fullDateTimeIn);
}
