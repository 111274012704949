import {Modal, ModalProps} from '@mp/common/modals/Modal';
import {isEmpty} from '@mp/common/utils/array';
import {toCurrency} from '@mp/common/utils/number';
import {loadAllVodProvidersService} from '@mp/filmweb/services/loadAllVodProviders.service';
import {FilmVodProvidersResult, loadFilmVodProvidersService} from '@mp/filmweb/services/loadFilmVodProviders.service';
import {FilmVodProvider, FwId, VodProvider} from '@mp/filmweb/types';
import React, {JSX, useEffect, useState} from 'react';
import {Loading} from '../../../../components/loading';
import styles from './VodProvidersModal.module.scss';

export function VodProvidersModal(props: ModalProps & {fwId: FwId}): JSX.Element {
    const {fwId, title, handleClose} = props;

    const [vodData, setVodData] = useState<FilmVodProvidersResult>();
    const [allVodProviders, setAllVodProviders] = useState<VodProvider[]>();

    useEffect(() => {
        Promise.all([loadFilmVodProvidersService(fwId), loadAllVodProvidersService()]).then(([filmVodProviders, allProviders]) => {
            const sortedVodProvidersVod: FilmVodProvider[] = [];
            allProviders
                .sort((a, b) => a.order - b.order)
                .filter((provider: VodProvider) => {
                    const matchedFilmVodProvider: FilmVodProvider = filmVodProviders.find((entry) => entry.vodProvider === provider.id);
                    if (matchedFilmVodProvider) {
                        sortedVodProvidersVod.push(matchedFilmVodProvider);
                    }
                });

            setVodData(sortedVodProvidersVod);
            setAllVodProviders(allProviders);
        });
    }, []);

    if (vodData == null || allVodProviders == null) {
        return (
            <Modal handleClose={handleClose} title={title}>
                <Loading />
            </Modal>
        );
    }

    const getProviderById = (id: number) => {
        return allVodProviders.find((v) => v.id === id);
    };

    return (
        <Modal handleClose={handleClose} title={title}>
            <div>
                {isEmpty(vodData) && <div>Brak VOD na których można obejrzeć</div>}
                <div className={styles.vodModal}>
                    {vodData.map((vod) => {
                        const vodUrl: URL = new URL(vod.link);
                        const prices: number[] = vod.payments?.map((f) => f.price);

                        const provider: VodProvider = getProviderById(vod.vodProvider);
                        const imgSrc: string = `https://fwcdn.pl/vodp${provider.path.replace('$', '1')}`;
                        const minPrice: number = isEmpty(prices) ? null : Math.min(...prices);

                        return (
                            <a key={vod.vodProvider} className={styles.vodModalItem} href={vodUrl.href} target="_blank" rel="noreferrer">
                                <img className={styles.vodModalItemL} src={imgSrc} alt={provider.displayName} />
                                <div className={styles.vodModalItemR}>{minPrice && <div>{`${toCurrency(minPrice / 100)}`}</div>}</div>
                            </a>
                        );
                    })}
                </div>
            </div>
        </Modal>
    );
}
