import {PatchServiceResponse, service} from '@mp/common/api';
import {MkDbMovie} from '../types';

export * from './loadCinemaProgram.service';

const SERVICE_NAME: string = 'cinemaProgram';

export type UpdateMkDvMovie = Pick<MkDbMovie, 'mkId' | 'fwId' | 'fwRate' | 'fwRateCount' | 'wantToWatchRate' | 'updated'>;

export function updateMkDbMovieService(data: UpdateMkDvMovie): Promise<PatchServiceResponse> {
    return service.patch(SERVICE_NAME, {data});
}
