import './_seriesItem.scss';
import {toDateString} from '@mp/common/utils/converters';
import {currentDate} from '@mp/common/utils/date';
import {zeroPrefix} from '@mp/common/utils/string';
import {createLinkToMovie} from '@mp/filmweb/utils/link';
import classNames from 'classnames';
import React, {JSX} from 'react';
import {CustomMenuElement, SettingMenu} from '../../../components/setting-menu/ui/SettingMenu';
import {CheckNewEpisodeState, CheckNewEpisodeType, Series} from '../types';
import {intToDay} from '../utils';
import {openNextEpisode} from '../utils/openNextEpisode';
import {searchForSubtitles} from '../utils/searchForSubtitles';

const MP_SERIES_ITEM: string = 'mp-series-item';
const NEW_EPISODE: string = 'new-episode';

interface SeriesItemProps {
    series: Series;
    onIncrementEpisode: (id: number, episode: number) => void;
    onSetEditView: (series: Series) => void;
    onUpdateEmissionDate: (series: Series) => void;
}

export class SeriesItem extends React.Component<SeriesItemProps> {
    public render() {
        const {series, onIncrementEpisode, onSetEditView, onUpdateEmissionDate} = this.props;
        const {id, episode} = series;

        const customMenuElements: Array<CustomMenuElement> = [
            {label: 'Oglądaj', onClick: () => openNextEpisode(series)},
            {label: 'Obejrzany', onClick: () => onIncrementEpisode(Number(id), Number(episode))},
            {label: 'Napisy', onClick: () => searchForSubtitles(series)},
            {label: 'Edytuj', onClick: () => onSetEditView(series)},
            {label: 'Aktualizuj emisję', onClick: () => onUpdateEmissionDate(series)}
        ];

        if (series.fid) {
            customMenuElements.push({label: 'Filmweb', onClick: () => window.open(createLinkToMovie(series.fid), '_blank')});
        }

        const seasonEpisode: string = `S${zeroPrefix(series.season)} E${zeroPrefix(series.episode)}`;

        return (
            <div key={series.id} className={MP_SERIES_ITEM} style={{backgroundColor: resolveColor(series)}}>
                <div className={`${MP_SERIES_ITEM}-left`}>
                    <span className="series-day">{intToDay(Number(series.day))}</span>
                    <span className="series-episode">{seasonEpisode}</span>
                    <span>{series.name}</span>
                </div>
                <div className={classNames(`${MP_SERIES_ITEM}-right`, {'with-label': series.checkNewEpisodeState})}>
                    {resolveNewEpisodeLabel(series.checkNewEpisodeState)}
                    <SettingMenu title={series.name} menuWidth={200} customMenuElements={customMenuElements} />
                </div>
            </div>
        );

        function resolveColor(s: Series): string {
            if (s.visible) {
                return 'rebeccapurple';
            }
            return s.end ? 'gray' : '#9b6fb9';
        }
    }
}

function resolveNewEpisodeLabel(checkNewEpisodeState: CheckNewEpisodeType): JSX.Element {
    if (!checkNewEpisodeState) {
        return null;
    }
    let isNewEpisode: boolean = false;
    let content: string = '';

    if (checkNewEpisodeState === CheckNewEpisodeState.LOADING) {
        content = 'loading...';
    } else if (checkNewEpisodeState === CheckNewEpisodeState.NO_DATE) {
        content = 'brak danych';
    } else {
        if (checkNewEpisodeState.getTime() <= currentDate().getTime()) {
            isNewEpisode = true;
        }
        content = toDateString(checkNewEpisodeState, 'dd.mm.yyyy');
    }
    return (
        <span
            className={classNames('new-episode-label', {
                [NEW_EPISODE]: isNewEpisode,
                [`no-${NEW_EPISODE}`]: !isNewEpisode
            })}
        >
            {content}
        </span>
    );
}
